import React from 'react'
import { useFetchBand } from '../../hooks/bands/useBands'
import Input from '../Input'
import PropTypes from 'prop-types'
import {
  useDeleteBandMember,
  useFetchBandMembers,
} from '../../hooks/member/useMember'
import { getUser } from '../../utils/cookieHelper'
import { useNavigate } from 'react-router-dom'
import { AiFillDelete, AiOutlineArrowLeft } from 'react-icons/ai'

const ManageMember = () => {
  const navigate = useNavigate()
  const { data: bandData, isUserBandLoading, isFetchError } = useFetchBand()

  const handleGoBack = () => {
    navigate(-1)
  }
  if (isUserBandLoading) return <div>Loading...</div>

  return (
    <div className="flex flex-col p-3">
      <div className="flex justify-between">
        <div
          className="flex items-center gap-2  mb-4 hover:cursor-pointer"
          onClick={handleGoBack}
        >
          <AiOutlineArrowLeft />
          <p className=" text-16 font-semibold">Manage Members</p>
        </div>
      </div>
      <div className="flex flex-col gap-3 bg-main-bg-dark p-2">
        <div className="   w-full lg:w-1/3 m-auto">
          <div className="flex gap-2 text-20">
            {/* <AiOutlineSearch />
           <AiOutlineEdit /> */}
          </div>
        </div>

        <div className="flex flex-row justify-between items-center ">
          <p className=" text-18 font-semibold text-white p-2">Band Details</p>
        </div>

        <form
          // onSubmit={(e) => handleForm(e)}
          className="flex flex-col text-left w-full gap-4 mb-4 p-2 text-white"
        >
          <div className="relative">
            {isUserBandLoading ? (
              <div>
                <Input
                  value="..."
                  name="text"
                  label="Band Name"
                  classes={`mb-4`}
                  disabled
                />
              </div>
            ) : isFetchError ? (
              <div className="my-4">Error Loading Data</div>
            ) : (
              <div>
                {bandData?.data.map((value) => (
                  <BandComponent key={value.id} band={value} />
                ))}
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  )
}

const BandComponent = ({ band }) => {
  const { user } = getUser()

  const { data: bandMemberData } = useFetchBandMembers(band.id)
  const deleteBandMember = useDeleteBandMember()

  if (!bandMemberData) return <div></div>
  return (
    <div>
      <div className="flex flex-row justify-between items-center ">
        <p className=" text-18 font-semibold text-white p-2">
          {band.name} Members
        </p>
      </div>
      {bandMemberData.data?.map((member) => {
        const isOwn = member.user.id === user.id
        const handleDeleteBandMember = (e) => {
          e.preventDefault()
          deleteBandMember.mutate({
            bandId: band.id,
            memberId: member.id,
          })
        }
        return (
          <div
            className="flex flex-row items-center justify-between gap-3"
            key={member.id}
          >
            <Input
              key={member.id}
              value={member.user.nickname}
              name="text"
              classes={`mb-4`}
              disabled
            />

            {!isOwn && (
              <div
                className="flex justify-center items-center w-4 hover:text-pink cursor-pointer"
                onClick={handleDeleteBandMember}
              >
                <AiFillDelete />
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}
BandComponent.propTypes = {
  band: PropTypes.any,
}

export default ManageMember
