export const findContainer = (playlists, songId) => {
  for (const setList of playlists) {
    for (const song of setList.songs) {
      if (song.songId === songId) {
        return setList.setListId
      }
    }
  }
  return null
}

export const findPlaylistIndex = (playlists, id) => {
  return playlists.findIndex((playlist) => playlist.setListId === id)
}
export const findPlaylistIndexWithId = (playlists, id) => {
  return playlists.findIndex((playlist) => playlist.id === id)
}
