import React from 'react';
import PropTypes from 'prop-types';

const Picker = ({ label, required = false, value, defaultValue, options, onChange, name, classes, disabled, icon }) => {
	return (
		<div className="flex flex-col w-full relative">
			<div className="flex flex-row justify-between items-center">
				<div className="flex flex-row" >
					<label className="pb-1 text-14">{label}</label>
					{required && (<span className="text-new-red">*</span>)}
				</div>
				{icon && <span>{icon}</span>}
			</div>
			<select
				defaultValue={defaultValue}
				value={value}
				name={name}
				onChange={onChange}
				className={`px-4 py-3 input-field text-gray-400 disabled:bg-main-bg-disabled-gray rounded-none ${classes}`}
				disabled={disabled}
			>
				{options.map((option) => (
					<option key={option.value} value={option.value}>
						{option.label}
					</option>
				))}
			</select>
		</div>
	);
};

Picker.propTypes = {
	label: PropTypes.string.isRequired,
	required: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.any, label: PropTypes.string })),
	onChange: PropTypes.func.isRequired,
	name: PropTypes.string,
	classes: PropTypes.string,
	disabled: PropTypes.bool,
	icon: PropTypes.node
};

export default Picker;
