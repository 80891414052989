import {
  useMutation,
  useQueryClient
} from '@tanstack/react-query'
import {
  useNavigate
} from 'react-router-dom'
import {
  forgotPassword,
  forgotPasswordByToken,
  login,
  register,
  registerBybandId,
  verifyEmail
} from '../../api/auth/auth'
import {
  useAuth
} from '../../utils/auth'
import {
  setTokens
} from '../../utils/cookieHelper'
import {
  toast
} from 'react-toastify'

export const useLogin = () => {
  const navigate = useNavigate()
  const auth = useAuth()
  return useMutation(
    ['login'],
    ({
      email,
      password
    }) => login(email, password), {
      onSuccess: (data) => {
        setTokens(data)
        auth.setLoggedIn(true)
        navigate('/')
      },
      onError: (e) => {
        toast.error(e.response.data.message || "Unexpected error occurred with login. Please try again!")
      }
    },
  )
}

export const useRegister = () => {
  const navigate = useNavigate()
  return useMutation(
    ['register'],
    ({
      email,
      password,
      nickname,
      bandName,
      bandId
    }) =>
    bandId ? registerBybandId(email, password, nickname, bandId) : register(email, password, nickname, bandName), {
      onSuccess: (data, variables, context) => {
        navigate('/login')
        console.log('Register', data, variables, context)
      },
      onError: (e) => {
        toast.error(e.response.data.message || "Unexpected error occurred with register. Please try again!")
      }
    },
  )
}

export const useForgotPass = () => {
  const navigate = useNavigate()

  return useMutation(['forgot-pass'], ({
    email
  }) => forgotPassword(email), {
    onSuccess: () => {
      navigate('/')
    },
  })
}
export const useVerifyEmail = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  return useMutation(['verify-email'], ({
    userId,
    token
  }) => verifyEmail(userId, token, token), {
    onSuccess: () => {
      queryClient.invalidateQueries("user-details")
      toast.success("Email verified Successfully")
      navigate('/calendar')
    },
    onError: (e) => {
      console.log(e);
      toast.error("Email verification Failed")
    }
  })
}
export const useForgotPassword = () => {
  const navigate = useNavigate()

  return useMutation(['forgot-password'], ({
    userId,
    token,
    password
  }) => forgotPasswordByToken(userId, token, password), {
    onSuccess: () => {
      toast.success("Password reset Successfully")
      navigate('/')
    },
    onError: (e) => {
      console.log(e);
      toast.error("Password reset Failed")
    }
  })
}