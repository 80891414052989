import React from 'react'
import { useRoutes } from 'react-router-dom'
import Authentication from './pages/Authentication'
import RegisterPage from './pages/RegisterPage'
import { ToastContainer } from 'react-toastify'
import ForgotPassword from './components/ForgotPassword'
import { RequireAuth } from './components/RequireAuth'
import AddSetList from './pages/SetList/AddSetList'
import { CalendarPage } from './pages/CalendarPage'
import { Profile } from './pages/Profile'
// import { SongListPage } from './pages/SetList/SongListPage'
import AddSong from './pages/SetList/AddSong'
import { SetListRenderer } from './pages/SetList/SetLists'
import AddPractice from './pages/AddPractice'
import AddGig from './pages/AddGig'
import NoMatch from './pages/NoMatch'
import { Chat } from './pages/Chat'
import AddMember from './components/Members/AddMember'
import ManageMember from './components/Members/ManageMember'
import VerifyEmail from './pages/VerifyEmail/VerifyEmail'
// import { DesktopSetList } from './pages/SetList/desktopSetList'
import ForgotPasswordPage from './pages/ForgotPassword/ForgotPassword'
import { AddSchedule } from './components/Schedule/AddSchedule'
import ScheduleDetail from './pages/ScheduleDetail'
import { MobileSetList } from './pages/SetList/MobileSetList'
import AddBand from './components/Bands/AddBand'

export default function Router() {
  const routes = useRoutes([
    { path: '/register', element: <RegisterPage /> },
    { path: '/login', element: <Authentication /> },
    { path: '/forgot-password', element: <ForgotPassword /> },
    {
      path: '/add-setlist',
      element: (
        <RequireAuth>
          <AddSetList />
        </RequireAuth>
      ),
    },
    {
      path: '/',
      element: (
        <RequireAuth>
          <CalendarPage />
        </RequireAuth>
      ),
    },
    {
      path: '/calendar',
      element: (
        <RequireAuth>
          <CalendarPage />
        </RequireAuth>
      ),
    },
    {
      path: '/profile',
      element: (
        <RequireAuth>
          <Profile />
        </RequireAuth>
      ),
    },
    {
      path: '/chat',
      element: (
        <RequireAuth>
          <Chat />
          {/* <ChatTest /> */}
        </RequireAuth>
      ),
    },
    {
      path: '/set-lists/songlist',
      element: (
        <RequireAuth>
          <MobileSetList />
          {/* <SongListPage /> */}
        </RequireAuth>
      ),
    },
    {
      path: '/add-song',
      element: (
        <RequireAuth>
          <AddSong />
        </RequireAuth>
      ),
    },
    {
      path: '/set-lists',
      element: (
        <RequireAuth>
          <SetListRenderer />
          {/* <DesktopSetList /> */}
        </RequireAuth>
      ),
    },
    {
      path: '/add-booking/:date',
      element: (
        <RequireAuth>
          <AddSchedule />
        </RequireAuth>
      ),
    },
    {
      path: '/add-practice/:date',
      element: (
        <RequireAuth>
          <AddPractice />
        </RequireAuth>
      ),
    },
    {
      path: '/add-gig/:date',
      element: (
        <RequireAuth>
          <AddGig />
        </RequireAuth>
      ),
    },
    {
      path: '/booking',
      element: (
        <RequireAuth>
          <ScheduleDetail />
        </RequireAuth>
      ),
    },
    {
      path: '/*',
      element: <NoMatch />,
    },
    {
      path: '/add-member',
      element: (
        <RequireAuth>
          <AddMember />
        </RequireAuth>
      ),
    },
    {
      path: '/add-band',
      element: (
        <RequireAuth>
          <AddBand />
        </RequireAuth>
      ),
    },
    {
      path: '/manage-member',
      element: (
        <RequireAuth>
          <ManageMember />
        </RequireAuth>
      ),
    },
    {
      path: '/verify/email',
      element: (
        <RequireAuth>
          <VerifyEmail />
        </RequireAuth>
      ),
    },
    {
      path: '/forget-password',
      element: (

        <ForgotPasswordPage />
      ),
    },
  ])

  return (
    <div className="w-full">
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        theme="colored"
        draggable
        pauseOnHover
      />
      {routes}
    </div>
  )
}
