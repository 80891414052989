import {
    axiosInstance
} from '../axiosInterceptor'

export const sendInvite = async ({
    email,
    bandId
}) => {
    const data = await axiosInstance.put(`/bands/${bandId}/members`, {
        email,
    })
    console.log(data.data, "sendInvite");
    return data
}

export const fetchBandMembers = async (id) => {
    const data = await axiosInstance.get(`/bands/${id}/members`)
    return data
}