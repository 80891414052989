import {
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'
import {
  toast
} from 'react-toastify'
import {
  fetchUserDetails,
  updateUserNickname
} from '../../api/User/users'

export const useUpdateUsers = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ['updateUsers'],
    (value) => updateUserNickname(value), {
      onSuccess: (data) => {
        console.log('🚀 ~ file: useUsers.js:12 ~ useUpdateUsers ~ data:', data)
        toast.success('Updated Nickname Successfully')
        queryClient.invalidateQueries(['user-details'])

      },
      onError: () => {
        toast.error('Error updating nickname')
      },
    }, {
      refetchOnWindowFocus: 'always',
      refetchInterval: false,
      refetchOnMount: true,
      cacheTime: 0,
      staleTime: 60000,
    },
  )
}

export const useUserDetails = () => {
  return useQuery(['user-details'], fetchUserDetails, {
    // onSuccess,
    // onError,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchOnMount: true,
    cacheTime: 0,
    retry: 3,
    staleTime: 60000,
  })
}