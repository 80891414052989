import React, { useState, useEffect, useRef } from 'react'
import { RiSendPlaneFill } from 'react-icons/ri'
import { useSocket } from '../components/Socket/SocketService'
import { getSelectedBand, getUser } from '../utils/cookieHelper.js'
import { useFetchChat } from '../hooks/chats/useChats.js'
import { useChatForm } from '../hooks/chats/useChatForm.js'
import Input from '../components/Input.jsx'
import { useQueryClient } from '@tanstack/react-query'
import moment from 'moment'

export const Chat = () => {
  const [onlineUsers, setOnlineUsers] = useState([])
  const { band } = getSelectedBand()
  const { user } = getUser()
  const userId = user?.id
  const bandId = band?.id

  const socket = useSocket(userId, bandId)

  const queryClient = useQueryClient()
  const scrollRef = useRef()

  const { formik, isLoading: isSending } = useChatForm()
  const { data: chats, isLoading: isChatLoading } = useFetchChat(band.id)

  useEffect(() => {
    const handleIncomingMessage = () => {
      queryClient.invalidateQueries(['chats'])
    }
    // Listen for incoming messages
    socket.on('message', handleIncomingMessage)
    // Listen for online users of the band
    socket.on('onlineUsers', (users) => {
      setOnlineUsers(users)
    })
    // Clean up the event listener when the component unmounts
    return () => {
      socket.off('message', handleIncomingMessage)
    }
  }, [])

  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behavior: 'smooth',
    })
  }, [chats])

  const isSender = (id) => {
    return user?.id === id
  }

  const isJsonString = (str) => {
    try {
      JSON.parse(str)
      return true
    } catch (e) {
      return false
    }
  }
  const parseEventMessage = (msg) => {
    if (isJsonString(msg)) {
      const msgObj = JSON.parse(msg)
      return (
        <div>
          <p>Date: {moment(msgObj?.date).local().calendar()}</p>
          <p>Time: {msgObj.time}</p>
          <p>Duration: {msgObj.durationInHours}</p>
          <p>Location: {msgObj.title}</p>
          <p>Notes: {msgObj.notes}</p>
        </div>
      )
    } else {
      return msg
    }
  }

  return (
    <div className="flex flex-col h-screen justify-between relative">
      <div className="bg-main-bg-pink p-4 flex items-center text-white sticky top-0 w-full z-10">
        <p className="text-lg">Band Chat</p>
      </div>
      <div className="flex-grow overflow-auto p-4 mb-20">
        {isChatLoading ? (
          <div className="flex justify-center items-center">Loading..</div>
        ) : (
          <div className="space-y-2 mt-5">
            {chats?.map((message) => (
              <div
                key={message.id}
                ref={scrollRef}
                className={`flex items-end ${
                  isSender(message?.sender?.userId)
                    ? 'justify-end'
                    : 'justify-start'
                }`}
              >
                <div
                  className={`flex items-end ${
                    isSender(message?.sender?.userId)
                      ? 'flex-row-reverse'
                      : 'flex-row'
                  }`}
                >
                  <div className="flex flex-col">
                    <img
                      src={`https://ui-avatars.com/api/?name=${message?.sender?.nickname}&rounded=true&background=random&size=24`}
                      alt={`Avatar of ${message?.sender?.nickname}`}
                      className="w-8 h-8 rounded-full m-2"
                    />
                    {onlineUsers.includes(message?.sender?.userId) && (
                      <p className="text-xs font-extralight ml-2">online</p>
                    )}
                  </div>

                  <div
                    className={`flex flex-col gap-1 ${
                      isSender(message?.sender?.userId)
                        ? 'flex-row-reverse items-end'
                        : 'flex-row items-start'
                    }`}
                  >
                    <div
                      className={`max-w-[70%] px-4 py-3 rounded-t-3xl ${
                        isSender(message?.sender?.userId)
                          ? 'bg-main-bg-pink text-white rounded-bl-3xl'
                          : 'bg-main-bg-light-green text-black rounded-br-3xl'
                      }`}
                    >
                      <div className="break-words">
                        {parseEventMessage(message?.message)}
                      </div>
                    </div>
                    <p className="text-xs font-extralight">
                      {moment(message?.createdAt).local().calendar()}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          formik.handleSubmit()
        }}
        className="flex items-center gap-4 bg-main-bg-pink p-4 fixed bottom-0 lg:w-4/5 md:w-96"
      >
        <Input
          value={formik.values.message}
          placeholder="Enter message..."
          name="message"
          label=""
          formik={formik}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.message && formik.errors.message}
          classes={`${
            formik.errors.message ? 'border border-pink' : ''
          } bg-main-bg-dark-pink flex-grow`}
        />
        <button disabled={isSending} type="submit" className="text-gray-300">
          <RiSendPlaneFill className="text-[25px]" />
        </button>
      </form>
    </div>
  )
}
