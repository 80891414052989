import {
  axiosInstance
} from '../axiosInterceptor'

export const createNewBand = async () => {
  const data = await axiosInstance.post(`bands`)
  return data
}

export const createBand = async ({ name }) => {
  const data = await axiosInstance.post(`/bands`, {
    name: name,
  })
  return data
}


export const fetchBand = async () => {
  const data = await axiosInstance.get(`bands`)
  return data
}

export const deleteBand = async (bandId) => {
  const data = await axiosInstance.delete(`bands/${bandId}`)
  return data
}
export const fetchBandAvailabilities = async (
  bandId
) => {

  const data = await axiosInstance.get(`bands/${bandId}/member-availabilities`)
  return data
}
export const fetchBandAvailabilitiesForDate = async (
  bandId,
  date
) => {

  const data = await axiosInstance.get(`bands/${bandId}/member-availabilities/${date}`)
  return data
}
export const updateAvailabilities = async (bandId, memberId, updateObject) => {

  const data = await axiosInstance.post(`bands/${bandId}/member-availabilities/${memberId}`, updateObject)
  return data
}

export const deleteBandMember = async (
  values
) => {
  const data = await axiosInstance.delete(`bands/${values.bandId}/members/${values.memberId}`)
  return data
}