import React, { useState } from 'react'
import Input from './Input'
import TextArea from './TextArea'
import Button from './Button'
import Picker from './Picker'
import {
  AiOutlineEdit,
  AiOutlineDownload,
  AiOutlineCheck,
} from 'react-icons/ai'
import { FaSquareFull } from 'react-icons/fa'
import { MdClear } from 'react-icons/md'
import { RiShareBoxLine } from 'react-icons/ri'
import { useSetList } from '../hooks/setLists/useSetLists'
import PropTypes from 'prop-types'
import { useScheduleForm } from '../hooks/schedule/useScheduleForm'
import { useDeleteSchedule } from '../hooks/schedule/useSchedule'
import { useNavigate } from 'react-router-dom'
import { PDFDownloadLink } from '@react-pdf/renderer'
import PdfContent from './DragAndDrop/PdfContent'
import { getSelectedBand } from '../utils/cookieHelper'
import { useFetchBandAvailabilitiesForDate } from '../hooks/bands/useBands'
import moment from 'moment'

const durationOptions = [
  { value: '', label: 'Select Duration' },
  { value: 0.5, label: '30 minutes' },
  { value: 1, label: '1 hour' },
  { value: 1.5, label: '1.5 hours' },
  { value: 2, label: '2 hours' },
  { value: 2.5, label: '2.5 hours' },
  { value: 3, label: '3 hours' },
  { value: 3.5, label: '3.5 hours' },
  { value: 4, label: '4 hours' },
  { value: 4.5, label: '4.5 hours' },
  { value: 5, label: '5 hours' },
  { value: 5.5, label: '5.5 hours' },
  { value: 6, label: '6 hours' },
  { value: 6.5, label: '6.5 hours' },
  { value: 7, label: '7 hours' },
  { value: 7.5, label: '7.5 hours' },
  { value: 8, label: '8 hours' },
]

const BandMemberAvailabilityStatus = {
  AVAILABLE: 'AVAILABLE',
  UNAVAILABLE: 'UNAVAILABLE',
  UNKNOWN: 'UNKNOWN',
}

const ScheduleForm = ({ date, type, editData, editMode = false }) => {
  const navigate = useNavigate()
  const { band } = getSelectedBand()
  const bandId = band?.id

  const [isEditMode, setIsEditMode] = useState(editMode)

  const { data: memberAvailability, isLoading: isAvailabilityLoading } =
    useFetchBandAvailabilitiesForDate(bandId, date)

  const { data: setListData, isLoading: isSetListLoading } = useSetList(bandId)
  const { formik, isLoading } = useScheduleForm(date, type, bandId, editData)
  const deleteSchedule = useDeleteSchedule()

  const downloadableSetList = setListData?.find(
    (sl) => sl.id === editData?.setListId,
  )

  const setListOptions = [
    { value: '', label: 'Select Set List' },
    ...(setListData?.map((setList) => ({
      value: setList.id,
      label: setList.title,
    })) || []),
  ]

  const handleForm = (e) => {
    e.preventDefault()
    formik.handleSubmit()
  }

  const handleDelete = (id) => {
    deleteSchedule.mutate({ bandId, id })
    navigate(-1)
  }

  const handleEdit = () => {
    setIsEditMode(false)
  }

  const handleRouteChange = () => {
    navigate('/calendar', { state: { tab: 'Availability' } })
  }

  const handleSecondaryButton = () => {
    isEditMode ? handleDelete(editData.id) : setIsEditMode(true)
  }

  const getContentByAvailability = (availability) => {
    switch (availability) {
      case BandMemberAvailabilityStatus.AVAILABLE:
        return <AiOutlineCheck size={20} />
      case BandMemberAvailabilityStatus.UNAVAILABLE:
        return <MdClear size={20} color="ffffff" />
      default:
        return <FaSquareFull size={20} className="text-gray-500" />
    }
  }

  const getClassnameByAvailability = (availability) => {
    switch (availability) {
      case BandMemberAvailabilityStatus.AVAILABLE:
        return 'p-1 bg-cyan-400'
      case BandMemberAvailabilityStatus.UNAVAILABLE:
        return 'p-1 bg-red-500'
      default:
        return 'p-1 bg-gray-500'
    }
  }

  if (isSetListLoading || isAvailabilityLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        Loading...
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-center mb-2">
        <p className="text-16 font-semibold">
          {moment(date).format('dddd, Do MMMM, YYYY')}
        </p>
      </div>
      {memberAvailability && (
        <div className="bg-main-bg-dark p-5 w-full lg:w-1/3 m-auto">
          <div className="relative flex-grow">
            <div className="flex flex-row justify-between items-center">
              <p className=" text-18 font-semibold text-white pb-2">
                Member Availability
              </p>
              <div
                className="text-20 text-white p-2 cursor-pointer"
                onClick={handleRouteChange}
              >
                <RiShareBoxLine />
              </div>
            </div>
            {memberAvailability?.data?.map((availability) => {
              return (
                <div
                  key={availability.user.id}
                  className="flex flex-row items-center gap-2 my-1"
                >
                  <div
                    className={getClassnameByAvailability(
                      availability.availability,
                    )}
                  >
                    {getContentByAvailability(availability.availability)}
                  </div>
                  <div className="text-white">{availability.user.nickname}</div>
                </div>
              )
            })}
          </div>
        </div>
      )}
      <div className="bg-main-bg-dark p-5  w-full lg:w-1/3 m-auto">
        {isEditMode && (
          <div className="flex flex-row justify-between items-center ">
            <p className=" text-18 font-semibold text-white py-2">
              {`Schedule ${type === 'GIG' ? 'Gig' : 'Practice'}`}
            </p>
            <div
              className="text-20 text-white p-2 cursor-pointer"
              onClick={handleEdit}
            >
              <AiOutlineEdit />
            </div>
          </div>
        )}
        <form
          onSubmit={(e) => handleForm(e)}
          className="flex flex-col text-left w-full gap-4 mb-4 text-white"
        >
          <div className="relative flex-grow">
            <Input
              disabled={isEditMode}
              value={formik.values.title}
              placeholder="Location"
              name="title"
              label="Location"
              required={true}
              formik={formik}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.title && formik.errors.title}
              classes={
                formik.errors.title ? 'border border-new-red text-14' : null
              }
            />
            {formik.errors.title ? (
              <div className="absolute right-0 top-auto text-new-red text-14 ">
                {formik.errors.title}
              </div>
            ) : null}
          </div>
          <div className="flex flex-row w-full gap-3 h-fit">
            <div className="relative flex-grow">
              <Input
                disabled={isEditMode}
                value={formik.values.time}
                placeholder="Time"
                name="time"
                label="Time"
                required
                type="time"
                formik={formik}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.time && formik.errors.time}
                classes={`${
                  formik.errors.time ? 'border border-new-red text-14' : null
                }`}
              />
              {formik.errors.time ? (
                <div className="absolute right-0 top-auto text-new-red text-14 ">
                  {formik.errors.time}
                </div>
              ) : null}
            </div>
            <div className="relative flex-grow">
              <Picker
                disabled={isEditMode}
                label="Duration"
                required
                value={formik.values.durationInHours}
                options={durationOptions}
                onChange={(e) =>
                  formik.setFieldValue('durationInHours', e.target.value)
                }
                name="durationInHours"
                error={
                  formik.touched.durationInHours &&
                  formik.errors.durationInHours
                }
                classes={`${
                  formik.errors.durationInHours
                    ? 'border border-new-red text-14'
                    : null
                }`}
              />
              {formik.errors.durationInHours ? (
                <div className="absolute right-0 top-auto text-new-red text-14 ">
                  {formik.errors.durationInHours}
                </div>
              ) : null}
            </div>
          </div>
          <div className="relative flex-grow">
            <Picker
              disabled={isEditMode}
              value={formik.values?.setListId}
              options={setListOptions}
              icon={
                isEditMode && (
                  <PDFDownloadLink
                    document={
                      <PdfContent
                        title={downloadableSetList?.title}
                        songs={downloadableSetList?.songs}
                      />
                    }
                    fileName={`${downloadableSetList?.title}.pdf`}
                  >
                    <AiOutlineDownload />
                  </PDFDownloadLink>
                )
              }
              label="Set List"
              required
              name="setListId"
              formik={formik}
              onChange={(e) =>
                formik.setFieldValue('setListId', e.target.value)
              }
              onBlur={formik.handleBlur}
              error={formik.touched.setListId && formik.errors.setListId}
              classes={`${
                formik.errors.setListId ? 'border border-new-red text-14' : null
              }`}
            />
            {formik.errors.setListId ? (
              <div className="absolute right-0 top-auto text-new-red text-14 ">
                {formik.errors.setListId}
              </div>
            ) : null}
          </div>
          <div className="relative flex-grow mb-3">
            <TextArea
              disabled={isEditMode}
              value={formik.values.notes}
              placeholder="Enter notes"
              name="notes"
              label="Notes"
              required
              type="text"
              formik={formik}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.notes && formik.errors.notes}
              classes={
                formik.errors.notes ? 'border border-new-red text-14' : null
              }
            />
            {formik.errors.notes ? (
              <div className="absolute right-0 top-auto text-new-red text-14">
                {formik.errors.notes}
              </div>
            ) : null}
          </div>
          {!isEditMode && (
            <Button
              bgColor={
                type === 'REHEARSAL' ? 'bg-main-bg-blue' : 'bg-main-bg-red'
              }
              text={isLoading ? 'Saving...' : 'Save'}
              size="md"
              borderRadius="0px"
              type="submit"
            />
          )}
        </form>
        <Button
          bgColor="transparent"
          text={!isEditMode ? 'Close' : 'Delete'}
          size="md"
          borderRadius="0px"
          color="white"
          border="2px solid white"
          classes={`w-full`}
          // onClick={() => handleDelete(editData.id)}
          onClick={handleSecondaryButton}
        />
      </div>
    </div>
  )
}

ScheduleForm.propTypes = {
  date: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  editData: PropTypes.object,
  editMode: PropTypes.bool,
}

export default ScheduleForm
