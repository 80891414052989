import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { useCreateBand } from './useBands'

export const useBandsForm = () => {
  const { mutate, isLoading } = useCreateBand()
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: { name: '' },
    validationSchema: Yup.object().shape({
        name: Yup.string().required('Required').min(2, 'Band name too short'),
    }),
    onSubmit: (values) => {
      handleSubmit(values)
      navigate(-1)
    },
  })

  const handleSubmit = async (values) => {
    try {
      await mutate(values)
    } catch (error) {
      console.log('Band error', error)
    }
  }

  const handleKeyDown = (event) => {
    formik.setFieldTouched(event.target.name, true, false)
    formik.validateField(event.target.name)
  }

  return { formik, isLoading, handleSubmit, handleKeyDown }
}
