import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { createBandSchedule, deleteBandSchedule, fetchBandSchedules, fetchBandSchedulesForDate, updateBandSchedule } from '../../api/Schedules/schedule'
import { toast } from 'react-toastify'

export const useFetchBandSchedules = (bandId) => {
  return useQuery(['band-schedules', bandId], fetchBandSchedules, {
    // onSuccess,
    // onError,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchOnMount: true,
    cacheTime: 0,
    staleTime: 60000,
  })
}

export const useFetchBandSchedulesForDate = (bandId, date) => {
  return useQuery(['band-schedules', bandId, date], fetchBandSchedulesForDate, {
    // onSuccess,
    // onError,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchOnMount: true,
    cacheTime: 0,
    staleTime: 60000,
  })
}

export const useCreateSchedule = (onSuccess) => {
  const queryClient = useQueryClient()
  return useMutation(
    ['create-booking'],
    (value) => createBandSchedule(value),
    {
      onSuccess: (data, variables, context) => {
        toast.success('Schedule added successfully')
        queryClient.invalidateQueries(['band-schedules'])
        onSuccess && onSuccess(data, variables, context)
      },
      onError: (err, _variables, _context) => {
        console.log("🚀 ~ useCreateSchedule ~ err, _variables, _context:", err, _variables, _context)
        toast.error('Error adding schedule')
      },
    },
    {
      refetchOnWindowFocus: 'always',
      refetchInterval: false,
      refetchOnMount: true,
      cacheTime: 0,
      staleTime: 60000,
    },
  )
}

export const useUpdateSchedule = (onSuccess) => {
  const queryClient = useQueryClient()
  return useMutation(
    ['update-booking'],
    (value) => updateBandSchedule(value),
    {
      onSuccess: (data, variables, context) => {
        toast.success('Schedule updated successfully')
        queryClient.invalidateQueries(['band-schedules'])
        onSuccess && onSuccess(data, variables, context)
      },
      onError: (err, _variables, _context) => {
        console.log("🚀 ~ useUpdateSchedule ~ err, _variables, _context:", err, _variables, _context)
        toast.error('Error updating schedule')
      },
    },
    {
      refetchOnWindowFocus: 'always',
      refetchInterval: false,
      refetchOnMount: true,
      cacheTime: 0,
      staleTime: 60000,
    },
  )
}

export const useDeleteSchedule = (bandId, scheduleId, onSuccess) => {
  const queryClient = useQueryClient()

  return useMutation(
    ['delete-schedule', bandId, scheduleId],
    (values) => {
      return deleteBandSchedule(values)
    },
    {
      onSuccess: (data, variables, context) => {
        toast.success('Schedule deleted successfully')
        queryClient.invalidateQueries(['band-schedules'])
        onSuccess && onSuccess(data, variables, context)
      },
      onError: (err, _variables, _context) => {
        console.log("🚀 ~ useDeleteSchedule ~ err, _variables, _context:", err, _variables, _context)
        toast.error('Failed to delete Schedule')
      },
    },
  )
}
