import {
    axiosInstance
} from '../axiosInterceptor'

export const createBandSchedule = async ({
    type,
    date,
    title,
    time,
    durationInHours,
    notes,
    setListId,
    bandId
}) => {
    const data = await axiosInstance.post(`/bands/${bandId}/schedules`, {
        type,
        date,
        title,
        time,
        durationInHours,
        notes,
        setListId
    })
    return data
}

export const updateBandSchedule = async ({
    type,
    title,
    time,
    durationInHours,
    notes,
    setListId,
    bandId,
    scheduleId
}) => {
    const data = await axiosInstance.put(`/bands/${bandId}/schedules/${scheduleId}`, {
        type,
        title,
        time,
        durationInHours,
        notes,
        setListId
    })
    return data
}

export const fetchBandSchedules = async ({ queryKey }) => {
    const bandId = queryKey[1]
    const data = await axiosInstance.get(`bands/${bandId}/schedules`)
    return data
}

export const fetchBandSchedulesForDate = async ({ queryKey }) => {
    const bandId = queryKey[1]
    const date = queryKey[2]
    const data = await axiosInstance.get(`bands/${bandId}/schedules/${date}`)
    return data
}

export const deleteBandSchedule = async (value) => {
    return await axiosInstance.delete(`bands/${value.bandId}/schedules/${value.id}`)
}

