import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer'
import React from 'react'
import PropTypes from 'prop-types'

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  section: {
    marginBottom: 15,
  },
  title: {
    fontSize: 24,
    marginBottom: 40,
    opacity: 0.5,
    fontWeight: 'bold',
  },
  paragraph: {
    marginBottom: 10,
  },
})
const PdfContent = ({ title, songs }) => (
  <Document>
    <Page style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.title}>{title}</Text>
        {songs?.map(({ songId, title }) => (
          <Text key={songId} style={styles.paragraph}>
            {title}
          </Text>
        ))}
      </View>
    </Page>
  </Document>
)
PdfContent.propTypes = {
  title: PropTypes.string.isRequired,
  songs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
}
export default PdfContent
