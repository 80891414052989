import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { useCreateSchedule, useUpdateSchedule } from './useSchedule'

export const useScheduleForm = (date, type, bandId, editData) => {
    const { mutate: createMutate, isLoading: isUpdateLoading } = useCreateSchedule()
    const { mutate: updateMutate, isLoading: isCreateLoading } = useUpdateSchedule()

    const loading = isCreateLoading || isUpdateLoading

    const navigate = useNavigate()

    const initialValues = {
        type: editData?.type !== undefined ? editData.type : type || '',
        date: editData?.date !== undefined ? editData.date : date || '',
        title: editData?.title || '',
        time: editData?.time || '',
        durationInHours: editData?.durationInHours || '',
        notes: editData?.notes || '',
        setListId: editData?.setListId || '',
    };

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            type: Yup.string().required('Type is required'),
            date: Yup.date().required('Date is required'),
            title: Yup.string().required('Title is required').min(2, 'Title too short'),
            time: Yup.string().required('Time is required'),
            durationInHours: Yup.number().required('Duration is required').min(0.5, 'Duration must be greater than 0.5'),
            notes: Yup.string().required('Notes are required'),
            setListId: Yup.number().required('Set List is required'),
        }),
        onSubmit: (values) => {
            handleSubmit(values)
            navigate(-1)
        },
    })

    const handleSubmit = async (values) => {
        try {
            const scheduleData = {
                type: values.type,
                date: values.date,
                title: values.title,
                time: values.time,
                durationInHours: parseFloat(values.durationInHours),
                notes: values.notes,
                setListId: parseInt(values.setListId),
                bandId: bandId
            };

            const updateScheduleData = editData ? { ...scheduleData, scheduleId: editData.id } : null;
            await (editData ? updateMutate(updateScheduleData) : createMutate(scheduleData));
        } catch (error) {
            console.log('Schedule error', error)
        }
    }

    const handleKeyDown = (event) => {
        formik.setFieldTouched(event.target.name, true, false)
        formik.validateField(event.target.name)
    }

    return { formik, loading, handleSubmit, handleKeyDown }
}
