import React, { useState, useEffect, useRef } from 'react'
import {
  AiOutlineBars,
  AiOutlineCalendar,
  AiOutlineMessage,
  AiOutlineUser,
  AiOutlineUp,
  AiOutlineDown
} from 'react-icons/ai'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { getSelectedBand, removeUser, setSelectedBandTokens } from '../utils/cookieHelper'
import { useFetchBand } from '../hooks/bands/useBands'
import { useAuth } from '../utils/auth'
import { toast } from 'react-toastify'
// import { FaBandcamp } from 'react-icons/fa'

const Navbar = () => {
  const navigate = useNavigate()
  const auth = useAuth()
  const { band } = getSelectedBand()
  const { data: bandData } = useFetchBand()

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleBandSelection = (band) => {
    setSelectedBandTokens(band)
    setIsDropdownOpen(false);
    window.location.reload();
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const isDropdownButton = event.target.matches('.AiOutlineUp, .AiOutlineDown');
      if (isDropdownOpen && !isDropdownButton) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isDropdownOpen]);

  useEffect(() => {
    if (bandData && typeof bandData !== 'undefined') {
      if (!band) {
        const bands = bandData.data;

        if (bands && bands.length >= 1) {
          setSelectedBandTokens(bands[0]);
        } else {
          toast.error('Your account is not associated with any bands. Please register a new band or request invitation from your band manager', {
            autoClose: 6000
          })
          logOut();
        }
      }
    }
  }, [bandData])

  const logOut = () => {
    removeUser()
    navigate('/login')
    auth.setLoggedIn(false)
    // window.location.reload();
  }

  return (
    <nav className="flex flex-row gap-2 justify-between sticky top-0 h-12 z-10">
      <div className="flex items-center justify-center w-12">
        <Link to={`/calendar`} className="">
          <h6 className="text-3xl text-white font-bold">BM</h6>
        </Link>
      </div>
      <div className="flex flex-row">
        <NavLink
          to={`/chat`}
          className="flex  gap-2 items-center justify-center"
        >
          <AiOutlineMessage />
          <div className="title">Chat</div>
        </NavLink>
        <NavLink
          to={`/calendar`}
          className="flex  gap-2 items-center justify-center"
        >
          <AiOutlineCalendar />
          <div className="title">Calendar</div>
        </NavLink>
        <NavLink
          to={`/set-lists`}
          className="flex  gap-2 items-center justify-center"
        >
          <AiOutlineBars />
          <div className="title">Setlists</div>
        </NavLink>
        {/* <NavLink
          to={`/profile`}
          className="flex  gap-2 items-center justify-center"
        >
          <AiOutlineUser />
          <div className="title">Profile</div>
        </NavLink> */}

        <div
          ref={dropdownRef}
          className="relative flex flex-row items-center justify-center"
        >
          <NavLink to={`/profile`} className="flex flex-row items-center justify-center dropdown-link">
            <AiOutlineUser />
            {band && (
              <div className="text-[10px] md:text-base pl-2">{band?.name}</div>
            )}
            <div
              onClick={handleDropdownToggle}
              className="AiOutlineDown p-2"
            >
              {isDropdownOpen ? (
                <AiOutlineUp className="AiOutlineUp" />
              ) : (
                <AiOutlineDown className="AiOutlineDown" />
              )}
            </div>
          </NavLink>
          {isDropdownOpen && (
            <div className="absolute flex flex-col right-0 top-10 w-[180px] mt-2 shadow-lg bg-main-bg-light-pink text-right">
              {bandData?.data?.map((band) => {
                if (!band) return;
                return (
                  <button
                    key={band?.id}
                    onClick={() => handleBandSelection(band)}
                    className="lg:px-10 px-5 py-2 text-sm text-gray-700 hover:bg-gray-200"
                  >
                    {band?.name}
                  </button>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </nav>
  )
}

export default Navbar
