import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import './Calendars.css';
import { useFetchBandSchedules } from '../../hooks/schedule/useSchedule';
// import { useFetchBand } from '../../hooks/bands/useBands';
import moment from 'moment';
import { getSelectedBand } from '../../utils/cookieHelper';
import { useFetchBand } from '../../hooks/bands/useBands';

export const createCalendar = (year, month, currentDate, schedule) => {
	const navigate = useNavigate();

	const datesWithBokings = new Set(schedule?.map(sc => sc.date));

	// Create a map to store booking types for each date
	const bookingTypeMap = {};
	schedule?.forEach(sc => {
		if (!bookingTypeMap[sc.date]) {
			bookingTypeMap[sc.date] = new Set();
		}
		bookingTypeMap[sc.date].add(sc.type);
	});

	const daysInMonth = new Date(year, month + 1, 0).getDate();
	const firstDayOfWeek = new Date(year, month, 1).getDay();

	const calendar = [];

	const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

	const addBooking = (date) => {
		navigate(`/add-booking/${date}`);
	};

	for (let day of weekdays) {
		calendar.push(
			<div key={day} className="weekday">
				{day}
			</div>
		);
	}

	for (let i = 0; i < firstDayOfWeek; i++) {
		calendar.push(<div key={`empty-${i}`} className="empty-day" />);
	}

	//   for (let day = 1; day <= daysInMonth; day++) {
	//     calendar.push(
	//       <div key={day} className="day p-3">
	//         {day}
	//       </div>
	//     );
	//   }

	for (let day = 1; day <= daysInMonth; day++) {
		const isToday =
			year === currentDate.getFullYear() && month === currentDate.getMonth() && day === currentDate.getDate();

		const selectedDate = moment(new Date(year, month, day)).format('YYYY-MM-DD')
		const hasBooking = datesWithBokings.has(selectedDate);

		const bookingTypes = bookingTypeMap[selectedDate];
		const hasRehearsal = bookingTypes && bookingTypes.has('REHEARSAL');
		const hasGig = bookingTypes && bookingTypes.has('GIG');

		let dayClassName = `cursor-pointer day w-10 h-10 flex items-center justify-center rounded-full ${isToday
			? 'today bg-main-bg-green'
			: ''}`;

		if (hasBooking) {
			if (hasRehearsal && hasGig) {
				dayClassName += ' bg-gradient-to-r from-new-red via-transparent to-new-blue';
			} else if (hasRehearsal) {
				dayClassName += ' bg-main-bg-blue';
			} else if (hasGig) {
				dayClassName += ' bg-main-bg-red';
			}
		}

		calendar.push(
			<div
				key={day}
				className={dayClassName}
				onClick={() => addBooking(selectedDate)}
			>
				{day}
			</div>
		);
	}

	return calendar;
};

function Calendars() {
	const { band } = getSelectedBand()
	const [currentDate, setCurrentDate] = useState(new Date());
	const [isMobile, setIsMobile] = useState(false);
	const [defaultBand, setDefaultBand] = useState();

	const { data: bandData } = useFetchBand()

	useEffect(() => {
		if (bandData && typeof bandData !== 'undefined') {
			if (!band) {
				const bands = bandData.data;

				if (bands && bands.length >= 1) {
					setDefaultBand(bands[0]);
				}
			}
		}
	}, [bandData])

	const bandId = band?.id
	const { data: scheduleData, isLoading: isScheduleLoading, isError: isScheduleError } = useFetchBandSchedules(bandId || defaultBand)
	const schedule = scheduleData?.data


	const updateIsMobile = () => {
		if (window.innerWidth <= 768) {
			setIsMobile(true)
		} else {
			setIsMobile(false)
		}
	}

	useEffect(() => {
		// Fetch or set your initial date as needed
		setCurrentDate(new Date());
		window.addEventListener('resize', updateIsMobile)

		updateIsMobile()

		return () => {
			window.removeEventListener('resize', updateIsMobile)
		}
	}, [])

	const handleNextMonth = () => {
		const monthOffset = isMobile ? 1 : 6;
		setCurrentDate(new Date(currentYear, currentMonth + monthOffset));
	};

	const handlePreviousMonth = () => {
		const monthOffset = isMobile ? -1 : -6;
		setCurrentDate(new Date(currentYear, currentMonth + monthOffset));
	};

	const currentYear = currentDate.getFullYear();
	const currentMonth = currentDate.getMonth();

	const calendarMonths = [];

	for (let i = 0; i < 6; i++) {
		const year = currentYear;
		const month = currentMonth + i;
		calendarMonths.push(
			<div key={`calendar-${year}-${month}`} className="calendar-container text-center bg-main-bg-dark">
				<div className="calendar-header text-white bg-main-bg-pink p-4">
					{new Date(year, month).toLocaleString('default', { month: 'long' })} {year}
				</div>
				<div className="calendar-grid p-3 text-white">{createCalendar(year, month, currentDate, schedule)}</div>
			</div>
		);
	}

	if (isScheduleError) return <div className="flex items-center justify-center h-screen">Error</div>;
	if (isScheduleLoading) {
		return <div className="flex items-center justify-center h-screen">Loading...</div>;
	}

	return (
		<div className="flex flex-wrap justify-center">
			<div className="flex justify-between p-4 w-full">
				<AiOutlineArrowLeft onClick={handlePreviousMonth} className="cursor-pointer" />
				<AiOutlineArrowRight onClick={handleNextMonth} className="cursor-pointer" />
			</div>
			<div className="calendar-wrapper flex flex-wrap gap-4 justify-center">{calendarMonths}</div>
		</div>
	);
}

export default Calendars;
