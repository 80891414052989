import React from 'react'
import PropTypes from 'prop-types'

import { AiFillDelete } from 'react-icons/ai'
import { RiMusicFill } from 'react-icons/ri'
import { RxDragHandleHorizontal } from "react-icons/rx";
import { CSS } from '@dnd-kit/utilities'
import { useDraggable } from '@dnd-kit/core'

import { useDeleteSong } from '../../hooks/songs/useSongs'

SongDragAble.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bandId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string.isRequired,
}
export default function SongDragAble({ id, title, bandId }) {
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: id, // Ensure id is a string for compatibility
      data: { id, title },
    })

  const deleteSong = useDeleteSong()

  const handleDelete = (id) => {
    deleteSong.mutate({ bandId, id })
  }

  const style = {
    transform: CSS.Translate.toString(transform),
    transition: 'transform  ease-in-out',
    willChange: 'transform',
    opacity: isDragging ? 0.7 : 1,
    cursor: 'grab',
    boxShadow: isDragging ? '0 0 10px rgba(0, 0, 0, 0.3)' : 'none',
  }
  return (
    <li className="flex gap-2 items-center w-full">
      <div
        ref={setNodeRef}
        style={style}
        className={`flex flex-grow items-center z-10 justify-start gap-2 p-2 text-white ${
          isDragging ? 'dragging' : ''
        }`}
      >
        <RxDragHandleHorizontal
          {...attributes}
          {...listeners}
          className="text-[28px] touch-none text-gray-400"
        />
        <RiMusicFill className="text-pink" />
        <p
          className="truncate text-ellipsis overflow-hidden w-[175px]">
          {title}
        </p>
      </div>
      <div className="cursor-pointer hover:text-pink text-white pr-2">
        <AiFillDelete onClick={() => handleDelete(id)} />
      </div>
    </li>
  )
}
