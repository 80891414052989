import { useLogin } from '../useAuth'
import { useFormik } from 'formik'
import { loginSchema } from './loginValidationSchema'

const initialValues = {
  email: '',
  password: '',
}

export const useLoginForm = () => {
  const { mutate, isLoading } = useLogin({})

  const handleLogin = (values) => {
    const { email, password } = values
    mutate({ email, password })
  }

  const onSubmit = (values) => {
    handleLogin(values)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit,
  })
  const handleKeyDown = (event) => {
    // Trigger validation on keydown event
    formik.setFieldTouched(event.target.name, true, false)
    formik.validateField(event.target.name)
  }
  return {
    handleLogin,
    formik,
    isLoading,
    handleKeyDown,
  }
}
