import { useState, createContext, useContext, useEffect } from 'react'
import { getTokens } from './cookieHelper'

const AuthContext = createContext()

const initialState = {
  loggedIn: false,
}

// eslint-disable-next-line react/prop-types
export const AuthProvider = ({ children }) => {
  //   const { data } = getUser();

  const [loggedIn, setLoggedIn] = useState(initialState.loggedIn)
  const [accessToken, setAccessToken] = useState('')

  useEffect(() => {
    const { accessToken, refreshToken } = getTokens()

    if (accessToken && refreshToken) {
      setAccessToken(accessToken)
      setLoggedIn(true)
    }
  }, [accessToken])

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <AuthContext.Provider value={{ accessToken, loggedIn, setLoggedIn }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
