/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import {
  AiFillDelete,
  AiOutlineEdit,
  AiOutlinePlus,
  AiOutlineArrowLeft
} from 'react-icons/ai'
import { useNavigate, useParams } from 'react-router-dom'
import { useDeleteSchedule, useFetchBandSchedulesForDate } from '../../hooks/schedule/useSchedule.js'
import Button from '../Button.jsx'
import moment from 'moment/moment.js'
import { getSelectedBand } from '../../utils/cookieHelper.js'

export const AddSchedule = () => {
  const { date } = useParams()
  const { band } = getSelectedBand()
  const bandId = band.id

  const { data: scheduleData, isLoading: isScheduleLoading, isError: isScheduleError } = useFetchBandSchedulesForDate(bandId, date)
  const schedule = scheduleData?.data

  const navigate = useNavigate()
  const [isMobile, setIsMobile] = useState(false)

  const updateIsMobile = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', updateIsMobile)

    updateIsMobile()

    return () => {
      window.removeEventListener('resize', updateIsMobile)
    }
  }, [])

  const handleAddPractice = () => {
    navigate(`/add-practice/${date}`);
  };

  const handleAddGig = () => {
    navigate(`/add-gig/${date}`);
  };

  const handleDetail = (schedule) => {
    navigate('/booking', { state: { schedule } })
  }

  const deleteSchedule = useDeleteSchedule()
  const handleDelete = (id) => {
    deleteSchedule.mutate({ bandId, id })
  }
  const handleGoBack = () => {
    navigate(-1);
  };

  if (isScheduleLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        Loading...
      </div>
    )
  }

  return (
    <div className="flex flex-col p-3 lg:px-0 h-full">
      <div className="flex justify-between items-center">
        <div className="flex items-center justify-center gap-2 mb-4">
          <AiOutlineArrowLeft onClick={handleGoBack} />
          <p className="text-16 font-semibold">{moment(date).format('dddd, Do MMMM, YYYY')}</p>
        </div>
      </div>
      <div className="flex-grow overflow-auto">
        {schedule?.length > 0 ? (
          <div className="my-4">
            <ul className="flex flex-col list-none p-0 gap-2 overflow-auto">
              {schedule?.map((sc) => (
                <li
                  key={sc.id}
                  className={`flex items-center justify-between p-4 bg-main-bg-dark text-white border-l-[12px] solid cursor-pointer  ${sc.type === 'GIG' ? 'border-l-new-red' : 'border-l-new-blue'}`}
                >
                  <div className="flex gap-2 items-center" onClick={() => handleDetail(sc)}
                  >
                    <p className="text-18">{sc.title}</p>
                    <p className="text-xs">{moment(sc.time, "HH:mm:ss").format("h:mm A")}</p>
                  </div>
                  <div className="flex gap-2 mt-1">
                    <AiFillDelete
                      size={20}
                      className="cursor-pointer"
                      onClick={() => handleDelete(sc.id)}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center my-4">
            <p className="text-18 font-bold">
              No bookings found for the selected day
            </p>
            <p className="text-14">
              Plan a new gig or practice to fill up your schedule!
            </p>
          </div>
        )}
      </div>
      <div className="absolute bottom-10 left-10 right-10 flex justify-between lg:justify-center p-0 gap-2">
        <Button
          bgColor="bg-main-bg-red"
          text="New Gig"
          size="md"
          borderRadius="0px"
          color="black"
          icon={<AiOutlinePlus />}
          onClick={handleAddGig}
        />
        <Button
          bgColor="bg-main-bg-blue"
          text="New Practice"
          size="md"
          borderRadius="0px"
          color="black"
          icon={<AiOutlinePlus />}
          onClick={handleAddPractice}
        />
      </div>
    </div>
  )
}
