import { useMutation, useQuery } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { fetchChats, sendChat } from '../../api/BandChats/chats'

export const useFetchChat = (bandId) => {
  return useQuery(['chats', bandId], fetchChats, {
    // onSuccess,
    // onError,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchOnMount: true,
    cacheTime: 0,
    staleTime: 60000,
  })
}

export const useSendChat = (onSuccess) => {
  return useMutation(
    ['send-chat'],
    (value) => sendChat(value),
    {
      onSuccess: (data, variables, context) => {
        onSuccess && onSuccess(data, variables, context)
      },
      onError: (err, _variables, _context) => {
        console.log("🚀 ~ useSendChat ~ err, _variables, _context:", err, _variables, _context)
        toast.error('Error sending chat')
      },
    },
    {
      refetchOnWindowFocus: 'always',
      refetchInterval: false,
      refetchOnMount: true,
      cacheTime: 0,
      staleTime: 60000,
    },
  )
}
