import React from 'react';
import PropTypes from 'prop-types';

const TextArea = ({ label = '', required = false, value, placeholder, onChange, name, classes, disabled }) => (
  <div className="flex flex-col w-full relative">
    <label className="pb-1 text-14">
      {label}
      {required && (<span className="text-new-red">*</span>)}
    </label>
    <textarea
      value={value}
      placeholder={placeholder}
      name={name}
      onChange={onChange}
      className={`px-4 py-3 input-field text-gray-400 rounded-none w-full ${classes}`}
      disabled={disabled}
    />
  </div>
);

TextArea.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  classes: PropTypes.string,
  disabled: PropTypes.bool,
};

export default TextArea;
