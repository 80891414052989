import axios from 'axios'
import { getTokens, removeUser } from '../utils/cookieHelper'

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 200000,
  headers: { 'ngrok-skip-browser-warning': 'true' },
})

axiosInstance.interceptors.request.use(
  function (config) {
    const { accessToken } = getTokens()
    if (accessToken) {
      config.headers['Authorization'] = 'Bearer ' + accessToken
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  },
)

axiosInstance.interceptors.response.use(
  function (response) {
    return response.data
  },
  function (error) {
    if (
      (error.response &&
        error.response.data.message === 'Not authorized, no token provided') ||
      (error.response &&
        error.response.data.message ===
        'No token provided, verification failed') ||
      (error.response &&
        error.response.data.message ===
        'Unauthorized')
    ) {
      removeUser()
      localStorage.setItem('axiosError', 'unauthorizedAccess')
      window.location.href = '/'
    }
    return Promise.reject(error)
  },
)
