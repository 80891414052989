import {
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'
import {
  createBand,
  createNewBand,
  deleteBand,
  fetchBand,
  fetchBandAvailabilities,
  fetchBandAvailabilitiesForDate,
  updateAvailabilities
} from '../../api/Bands/bands'
import {
  toast
} from 'react-toastify'

export const useFetchBand = () => {
  return useQuery(['getBands'], () => fetchBand(), {
    refetchInterval: false,
    refetchOnMount: 'always',
    refetchOnWindowFocus: true,
    staleTime: 60000,
  })
}

export const useCreateNewBand = () => {
  return useQuery(['createNewBands'], () => createNewBand(), {
    refetchInterval: false,
    refetchOnMount: 'always',
    refetchOnWindowFocus: true,
    staleTime: 60000,
  })
}

export const useCreateBand = (onSuccess) => {
  const queryClient = useQueryClient()
  return useMutation(
    ['createBand'],
    (value) => createBand(value),
    {
      onSuccess: (data, variables, context) => {
        toast.success('Added Band Successfully')
        queryClient.invalidateQueries(['getBands'])
        onSuccess && onSuccess(data, variables, context)
      },
      onError: (err, _variables, _context) => {
        console.log("🚀 ~ useCreateBand ~ err, _variables, _context:", err, _variables, _context)
        toast.error(err?.response?.data?.message || 'Error adding band')
      },
    },
    {
      refetchOnWindowFocus: 'always',
      refetchInterval: false,
      refetchOnMount: true,
      cacheTime: 0,
      staleTime: 60000,
    },
  )
}

export const useDeleteBand = (onSuccess) => {
  const queryClient = useQueryClient()

  return useMutation(
    ['deleteBand'],
    (value) => {
      return deleteBand(value.id)
    }, {
      onSuccess: (data, variables, context) => {
        toast.success('Band Deleted Successfully')
        queryClient.invalidateQueries(['getBands'])
        window.location.reload()
        onSuccess && onSuccess(data, variables, context)
      },
      onError: () => {
        toast.error('Failed to delete Band')
      },
    },
  )
}

export const useFetchBandAvailabilities = (id) => {
  if (id)
    return useQuery([`bandAvailabilities`], () => fetchBandAvailabilities(id))
}

export const useFetchBandAvailabilitiesForDate = (id, date) => {
  return useQuery([`bandAvailabilitiesForDate`], () => fetchBandAvailabilitiesForDate(id, date))
}
export const useUpdateAvailability = (onSuccess) => {
  const queryClient = useQueryClient()

  return useMutation(
    ['bandAvailability'],
    (value) => {
      return updateAvailabilities(value.id, value.memberId, value.updateObject)
    }, {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(['bandAvailabilities'])
        onSuccess && onSuccess(data, variables, context)
      },
      onError: (err, _variables, _context) => {
        console.log("🚀 ~ useUpdateAvailability ~ err, _variables, _context:", err, _variables, _context)
        toast.error(err?.response?.data?.message || 'Error updating availability')
        queryClient.invalidateQueries(['bandAvailabilities'])
      },
    },
  )
}