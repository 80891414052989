import React from 'react'
import AvailabilityTable from './Availability/AvailabilityTable'
import { useFetchBand } from '../hooks/bands/useBands'

const Availability = () => {
  const generateMonthlyData = (numberOfMonths) => {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const currentMonth = currentDate.getMonth() + 1 // Months are zero-based, so add 1

    const getLastDayOfMonth = (year, month) => {
      return new Date(year, month, 0).getDate()
    }

    const monthsData = []

    for (let i = 0; i < numberOfMonths; i++) {
      const year = currentYear + Math.floor((currentMonth + i - 1) / 12)
      const month = (currentMonth + i) % 12 || 12 // Get a number from 1 to 12
      const daysArray = []
      const firstDay = new Date(year, month - 1, 1)
      const lastDay = new Date(year, month, 0)
      for (let day = firstDay; day <= lastDay; day.setDate(day.getDate() + 1)) {
        const formattedDate = `${day.getFullYear()}-${String(
          day.getMonth() + 1,
        ).padStart(2, '0')}-${String(day.getDate()).padStart(2, '0')}`

        daysArray.push(formattedDate)
      }
      monthsData.push({
        year,
        month,
        monthName: new Intl.DateTimeFormat('en-US', { month: 'long' }).format(
          new Date(year, month - 1, 1),
        ),
        daysInMonth: getLastDayOfMonth(year, month),
        days: daysArray,
      })
    }
    return monthsData
  }
  const numberOfMonths = 24 // Set the number of months you want to display
  const monthDatas = generateMonthlyData(numberOfMonths)
  const { isLoading: isUserBandLoading } = useFetchBand()
  if (isUserBandLoading)
    return (
      <div className="flex  items-center justify-center h-screen">
        Loading...
      </div>
    )
  return (
    <div>
      {monthDatas.map((monthData, index) => (
        <AvailabilityTable key={index} monthData={monthData} />
      ))}
    </div>
  )
}

export default Availability
