import React from 'react'
import PropTypes from 'prop-types'
import { AiOutlineCheck } from 'react-icons/ai'
import { MdClear } from 'react-icons/md'

import {
  useFetchBandAvailabilities,
  useUpdateAvailability,
} from '../../hooks/bands/useBands'
import { getSelectedBand } from '../../utils/cookieHelper'
import { useQueryClient } from '@tanstack/react-query'
import { useFetchBandMembers } from '../../hooks/member/useMember'

const BandMemberAvailabilityStatus = {
  AVAILABLE: 'AVAILABLE',
  UNAVAILABLE: 'UNAVAILABLE',
  UNKNOWN: 'UNKNOWN',
}

const AvailabilityTable = ({ monthData }) => {
  const { band } = getSelectedBand()
  const bandId = band.id
  // Function to check if a day is a weekend
  const isWeekend = (day) => day === 0 || day === 6
  const weekendColor = 'bg-red-300'

  const {
    data: userAvailabilityData,
    isLoading,
    isError: isFetchError,
  } = useFetchBandAvailabilities(bandId)

  const { data: bandMemberData, isLoading: isBandLoading } =
    useFetchBandMembers(bandId)

  // Function to generate the table headers based on the number of days in the month
  const generateTableHeaders = () => {
    const headers = []
    for (let day = 1; day <= monthData.daysInMonth; day++) {
      headers.push(
        <th key={day}>
          <div
            className={
              isWeekend(
                new Date(monthData.year, monthData.month - 1, day).getDay(),
              )
                ? `${weekendColor} weekend`
                : ''
            }
          >
            {day}
          </div>
        </th>,
      )
    }
    return headers
  }
  const getContentByAvailability = (availability) => {
    switch (availability) {
      case BandMemberAvailabilityStatus.AVAILABLE:
        return <AiOutlineCheck size={20} />
      case BandMemberAvailabilityStatus.UNAVAILABLE:
        return <MdClear size={20} color="ffffff" />
      default:
        return ''
    }
  }
  const getReverseOfcurrentByAvailability = (availability) => {
    switch (availability) {
      case BandMemberAvailabilityStatus.AVAILABLE:
        return BandMemberAvailabilityStatus.UNAVAILABLE
      case BandMemberAvailabilityStatus.UNAVAILABLE:
        return BandMemberAvailabilityStatus.UNKNOWN
      default:
        return BandMemberAvailabilityStatus.AVAILABLE
    }
  }
  const getClassnameByAvailability = (availability, day) => {
    switch (availability) {
      case BandMemberAvailabilityStatus.AVAILABLE:
        return 'bg-cyan-400'
      case BandMemberAvailabilityStatus.UNAVAILABLE:
        return 'bg-red-500'
      default:
        if (isWeekend(day)) return weekendColor
        else return 'bg-white'
    }
  }
  const getMemberAvabilitiesOfTheDate = (memberAvailabilities, date) => {
    const availability = memberAvailabilities.find(
      (availability) => availability.date == date,
    )
    if (availability) return availability.status
    return BandMemberAvailabilityStatus.UNKNOWN
  }
  // Function to generate the user rows with availability status
  const generateUserRows = () => {
    // show the rows of all band members
    return bandMemberData.data.map((member, index) => {
      // const isOwnRow = currentUser.id == member.user.id
      const user = userAvailabilityData.data.find(
        (data) => data.userId == member.user.id,
      ) || { nickname: member.user.nickname, memberAvailabilities: [] }
      return (
        <tr
          key={index}
          className={'row-wrapper'}
        >
          <td className="bg-white fixed-nickname-td">{user.nickname}</td>
          {monthData.days.map((day, dayIndex) => {
            const availability = getMemberAvabilitiesOfTheDate(
              user.memberAvailabilities,
              day,
            )
            return (
              <td
                key={dayIndex}
                onClick={() => handleAvailabilityToggle(day, availability, member.id)}
                className={`${getClassnameByAvailability(
                  availability,
                  new Date(
                    monthData.year,
                    monthData.month - 1,
                    dayIndex + 1,
                  ).getDay(),
                )}  `}
              >
                {getContentByAvailability(availability)}
              </td>
            )
          })}
        </tr>
      )
    })
  }
  function replaceOrAddObject(array, keyName, newObject) {
    const index = array.findIndex(
      (item) => item[keyName] === newObject[keyName],
    )

    if (index !== -1) {
      array[index] = newObject
    } else {
      array.push(newObject)
    }

    return array
  }
  const updateAvailabilityOnServer = useUpdateAvailability()
  const updateLocalVariable = (oldData, memberId, updateObject) => {
    const user = oldData.data.find((data) => data.userId == memberId) || {
      nickname: '-',
      memberAvailabilities: [],
    }

    user.memberAvailabilities = replaceOrAddObject(
      user.memberAvailabilities,
      'date',
      updateObject,
    )
    return replaceOrAddObject(oldData.data, 'userId', user)
  }
  const queryClient = useQueryClient()

  const handleAvailabilityToggle = (date, status, memberId) => {
    const updateObject = {
      date,
      status: getReverseOfcurrentByAvailability(status),
    }
    let queryData = queryClient.getQueryData(['bandAvailabilities'])
    var updatedData = updateLocalVariable(queryData, memberId, updateObject)
    queryClient.setQueryData(['bandAvailabilities'], { data: updatedData })
    updateAvailabilityOnServer.mutate({
      id: bandId,
      memberId,
      updateObject,
    })
  }
  if (isLoading || isBandLoading)
    return (
      <div className="flex  items-center justify-center h-screen">
        Loading...
      </div>
    )
  if (isFetchError)
    return (
      <div className="flex items-center justify-center h-screen">No data</div>
    )
  return (
    <div>
      <div className="text-xl flex justify-center pt-10">
        <div className="text-xl font-bold">{`${monthData.monthName} ${monthData.year}`}</div>
      </div>
      <div className="max-w-full overflow-x-auto">
        <div className="flex flex-col relative">
          <div className="overflow-x-auto">
            <table
              className="availability-table bg-gray-500"
              align="left"
              cellSpacing="10"
              cellPadding="10"
            >
              <caption align="top"></caption>
              <thead>
                <tr>
                  <th className="bg-[#2a2d34] fixed-nickname-td"></th>
                  {generateTableHeaders()}
                </tr>
              </thead>
              <tbody>{generateUserRows()}</tbody>
            </table>
          </div>
        </div>
        {
          <div className=" text-right sticky block sm:hidden opacity-30 mt-0">
            {'<< swipe >>'}
          </div>
        }
      </div>
    </div>
  )
}

AvailabilityTable.propTypes = {
  monthData: PropTypes.object,
  userData: PropTypes.object,
}

export default AvailabilityTable
