import React, { useState, useEffect } from 'react'
import {
    AiOutlineArrowLeft,
    AiOutlinePlus
} from 'react-icons/ai'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DndContext, KeyboardSensor, PointerSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core'
import SongDragAble from '../../components/DragAndDrop/SongDragAble'
import SongDropAble from '../../components/DragAndDrop/SongDropAble'
import { useSongsList } from '../../hooks/songs/useSongs'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { addSongToSetList } from '../../api/BandSongs/set-lists'
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import { useOrderSong, useSetListID } from '../../hooks/setLists/useSetLists'

export const MobileSetList = () => {
    const [listSongs, setListSongs] = useState()
    const [isDragging, setIsDragging] = useState(false)
    const navigate = useNavigate();
    const location = useLocation()
    const { setlist } = location.state

    const { data: setListObj } = useSetListID(setlist.bandId, setlist.id)
    const { data: songList, isLoading } = useSongsList(setlist.bandId)

    useEffect(() => {
        if (setListObj) {
            setListSongs(setListObj.songs || [])
        }
    }, [setListObj])

    const useOrderSongHook = useOrderSong()

    const queryClient = useQueryClient()
    const addSong = useMutation((values) => addSongToSetList(values), {
        onSuccess: () => {
            queryClient.invalidateQueries([`set-lists${setlist.bandId}`])
            queryClient.invalidateQueries([`set-lists`])
            toast.success('Song added successfully')
        },
        onError: () => {
            toast.error('Not able to add song')
        },
    })

    const handleGoBack = () => {
        navigate(-1)
    }

    const handleAddSong = () => {
        navigate('/add-song')
    }

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    )

    const handleDragStart = () => {
        setIsDragging(true)
    }

    const handleDragEnd = async (event) => {
        const { active, over } = event
        if (!active && !over) {
            return
        }
        setIsDragging(false)
        if (active?.data?.current.sortable !== undefined && over?.data?.current.sortable !== undefined) {
            const { id: activeId } = active
            const { id: overId } = over

            const updatedListSongs = [...listSongs]

            const activeIndex = updatedListSongs.findIndex(
                (song) => song.songId === activeId,
            )

            const overIndex = updatedListSongs.findIndex(
                (song) => song.songId === overId,
            )

                // Swap the songs' positions
                ;[
                    updatedListSongs[activeIndex],
                    updatedListSongs[overIndex],
                ] = [
                        updatedListSongs[overIndex],
                        updatedListSongs[activeIndex],
                    ]

            const newOrderList = updatedListSongs.map(
                (song, i) => ({
                    songId: song.id,
                    order: i + 1,
                }),
            )
            useOrderSongHook.mutate({
                bandId: setlist?.bandId,
                setListId: setlist?.id,
                newOrderList,
            })
            setListSongs(updatedListSongs)
        } else {
            const { id: songId } = active.data.current

            if (!over) return
            const { playlistId } = over.data.current

            const isSongInSetList = setListObj?.songs.some(
                (song) => song.id === songId,
            )

            if (isSongInSetList) return toast.info('Song already added!');

            addSong.mutate({
                songId: songId,
                bandId: setlist?.bandId,
                setListId: playlistId,
            })
        }
    }

    if (isLoading) {
        return <div className="flex justify-center items-center">Loading.....</div>
    }

    return (
        <div className="flex flex-col p-3">
            <div className="flex justify-between">
                <div
                    className="flex items-center gap-2 mb-4 cursor-pointer"
                    onClick={handleGoBack}
                >
                    <AiOutlineArrowLeft />
                    <p className=" text-16 font-semibold">Manage Set List</p>
                </div>
            </div>
            <div className="flex flex-col mb-4">
                <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} sensors={sensors}>
                    <div className="flex flex-col justify-between">
                        <div className="my-4 w-full overflow-auto">
                            {
                                (setListObj && listSongs) && (
                                    <SongDropAble
                                        id={setListObj.setListId}
                                        playlistId={setListObj.id}
                                        bandId={setListObj.bandId}
                                        title={setListObj.title}
                                        songs={listSongs}
                                    />
                                )
                            }
                        </div>
                        <div className="songlist my-4 w-full">
                            <div className={`flex flex-col justify-self-end bg-main-bg-dark p-2 ${isDragging ? '' : ' h-full overflow-y-auto overflow-x-hidden'}`}>
                                <div className="flex justify-between items-center">
                                    <p className=" text-18 font-semibold text-white p-2">Songs List</p>
                                    <div className="flex flex-row items-center gap-1 text-20 text-white" onClick={handleAddSong}>
                                        <p className="text-14 font-semibold text-white p-2">Add Song</p>
                                        <AiOutlinePlus />
                                    </div>
                                </div>
                                <ul className="flex flex-col flex-grow list-none gap-1">
                                    {songList?.data?.map(({ id, title }) => (
                                        <SongDragAble
                                            key={id}
                                            id={id}
                                            title={title}
                                            bandId={setlist?.bandId}
                                        />
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </DndContext>
            </div>
        </div>
    )
}
