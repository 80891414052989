import { axiosInstance } from '../axiosInterceptor'

export const sendChat = async ({ message, bandId }) => {
    const data = await axiosInstance.post(`/bands/${bandId}/chats`, {
        message: message,
    })
    return data
}

export const fetchChats = async ({ queryKey }) => {
    const bandId = queryKey[1]
    return await axiosInstance.get(`bands/${bandId}/chats`)
}