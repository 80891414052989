import React from "react";
import PropTypes from 'prop-types'
import { useSortable } from "@dnd-kit/sortable";
import { RxDragHandleHorizontal } from "react-icons/rx";
import { CSS } from "@dnd-kit/utilities";

SongSortableItem.propTypes = {
    id: PropTypes.string,
    playlistId: PropTypes.number,
    title: PropTypes.string.isRequired,
}

export default function SongSortableItem({ playlistId, id, title }) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: id,
        data: { type: 'set list', playlistId: playlistId },
    })

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
        cursor: 'grab',
    }

    return (
        <div
            ref={setNodeRef}
            style={style}
            className="flex justify-between items-center gap-2 p-2"
        >
            <RxDragHandleHorizontal
                {...attributes}
                {...listeners}
                className="text-[28px] touch-none text-gray-400"
            />
            <div className="flex items-center gap-2">
                <p className="truncate text-ellipsis overflow-hidden w-[175px]">
                    {title}
                </p>
            </div>
        </div>
    );
}