import React from 'react'
import Input from './Input'
import { useNavigate } from 'react-router-dom'
import Button from './Button'
import { useForgotPassForm } from '../hooks/auth/components/useForgotPass'

const ForgotPassword = () => {
  const { formik } = useForgotPassForm()

  const navigate = useNavigate()
  const routeChange = () => {
    navigate(`/login`)
  }

  const handleForm = (e) => {
    e.preventDefault()
    formik.handleSubmit()
  }
  return (
    <div className="flex flex-col items-center h-auto  m-4 mt-24">
      <h2 className="text-6xl mb-12 text-pink">BndMgr.com</h2>
      <div className="flex flex-col text-left w-96 px-4 pt-6 pb-4 bg-main-bg-dark text-white">
        <p className="text-3xl mb-8">Forgot Password</p>
        <form
          onSubmit={(e) => handleForm(e)}
          className="flex flex-col text-left w-full gap-4 mb-4"
        >
          <div className="relative">
            <Input
              value={formik.values.email}
              placeholder="Enter Email"
              name="email"
              label="Email"
              formik={formik}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.username && formik.errors.username}
              classes={formik.errors.email ? 'border border-new-red' : null}
            />
            {formik.errors.email ? (
              <div className="absolute right-0 top-auto text-new-red text-14">
                {formik.errors.email}
              </div>
            ) : null}
          </div>

          <Button
            bgColor="bg-main-bg-pink"
            text="Send Verification Link"
            size="md"
            borderRadius="0px"
            type="submit"
          />
          {/* </div> */}
        </form>
        <Button
          bgColor="transparent"
          text="Back to Login"
          size="md"
          borderRadius="0px"
          color="white"
          border="2px solid white"
          onClick={routeChange}
        />
      </div>
    </div>
  )
}

export default ForgotPassword
