import Cookies from 'js-cookie'

const COOKIE_NAME = 'npuinf_bnds'
const COOKIE_NAME_TOKENS = 'npuinf_bnds_tokens'
const COOKIE_NAME_SELECTED_TOKENS = 'npuinf_selected_bnds_tokens'

export const setUser = (data) => {
  Cookies.set(COOKIE_NAME, JSON.stringify(data), { expires: 1, secure: true })
}

export const setTokens = (data) => {
  Cookies.set(COOKIE_NAME_TOKENS, JSON.stringify(data), {
    expires: 1,
    secure: true,
  })
}

export const setSelectedBandTokens = (data) => {
  const bandData = {id: data.id, name: data.name}
  Cookies.set(COOKIE_NAME_SELECTED_TOKENS, JSON.stringify(bandData), {
    expires: 1,
    secure: true,
  })
}

export const getSelectedBand = () => {
  const data = Cookies.get(COOKIE_NAME_SELECTED_TOKENS)
  if (data) {
    const cookie_data = JSON.parse(data)
    return { band: cookie_data }
  }
  return {
    band: '',
  }
}

export const getUser = () => {
  const data = Cookies.get(COOKIE_NAME)
  if (data) {
    const cookie_data = JSON.parse(data)
    return { user: cookie_data.data }
  }
  return {
    user: '',
  }
}

export const getTokens = () => {
  const data = Cookies.get(COOKIE_NAME_TOKENS)
  if (data) {
    const cookie_data = JSON.parse(data)
    return cookie_data.data
  }

  return {
    accessToken: '',
    user: '',
  }
}

export const removeUser = () => {
  Cookies.remove(COOKIE_NAME)
  Cookies.remove(COOKIE_NAME_TOKENS)
  Cookies.remove(COOKIE_NAME_SELECTED_TOKENS)
}

export const removeSelectedBand = () => {
  Cookies.remove(COOKIE_NAME_SELECTED_TOKENS)
}
