import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import ScheduleForm from '../components/ScheduleForm';

const ScheduleDetail = () => {
	const location = useLocation()
	const { schedule } = location.state
	const navigate = useNavigate();

	const handleGoBack = () => {
		navigate(-1);
	};

	return (
		<div className="flex flex-col p-3">
			<div className="flex items-center gap-2  mb-4">
				<AiOutlineArrowLeft onClick={handleGoBack} />
				<p className=" text-16 font-semibold">{`Schedule ${schedule.type === 'GIG' ? 'Gig' : 'Practice'}`}</p>
			</div>
			<ScheduleForm date={schedule.date} type={schedule.type} editData={schedule} editMode={true}/>
		</div>
	);
};

export default ScheduleDetail;
