import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import ScheduleForm from '../components/ScheduleForm';

const AddGig = () => {
	const { date } = useParams();
	const location = useLocation();
	const type = location.pathname.includes('add-gig') ? 'GIG' : 'REHEARSAL';
	const navigate = useNavigate();

	const handleGoBack = () => {
		navigate(-1);
	};

	return (
		<div className="flex flex-col p-3">
			<div className="flex items-center gap-2  mb-4">
				<AiOutlineArrowLeft onClick={handleGoBack} />
				<p className=" text-16 font-semibold">Add Gig</p>
			</div>
			<ScheduleForm date={date} type={type} />
		</div>
	);
};

export default AddGig;
