import React from 'react'

import Register from '../components/Register'

const RegisterPage = () => {


  return (
    <div className="flex flex-col items-center h-auto m-4 mt-10">
      <h2 className="text-6xl mb-12 text-pink">BndMgr.com</h2>

      <Register />
    </div>
  )
}

export default RegisterPage
