import React from 'react'
import PropTypes from 'prop-types'
import { RiMusicFill } from 'react-icons/ri'
import { CSS } from '@dnd-kit/utilities'
import { useSortable } from '@dnd-kit/sortable'

SortableItem.propTypes = {
  id: PropTypes.string,
  playlistId: PropTypes.number,
  title: PropTypes.string.isRequired,
}
export default function SortableItem({ playlistId, id, title }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: id,
    data: { type: 'set list', playlistId: playlistId },
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    cursor: 'grab',
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="flex justify-between items-center gap-2 mb-2"
    >
      <div className="flex items-center gap-2">
        <RiMusicFill className="text-pink" />
        <p className="truncate text-ellipsis overflow-hidden w-[175px]">
          {title}
        </p>
      </div>
    </div>
  )
}
