import {
    useForgotPassword
} from '../useAuth'
import {
    useFormik
} from 'formik'
import {
    forgotPassHandlerSchema,
} from './loginValidationSchema'

const initialValues = {
    password: '',
    cpassword: '',
}

export const useForgotPassHandler = (userId, token) => {
    const {
        mutate,
        isLoading
    } = useForgotPassword({})

    const handleLogin = (values) => {
        const {
            password
        } = values
        mutate({
            userId,
            token,
            password
        })
    }

    const onSubmit = (values) => {
        handleLogin(values)
    }

    const formik = useFormik({
        initialValues,
        validationSchema: forgotPassHandlerSchema,
        onSubmit,
    })
    const handleKeyDown = (event) => {
        // Trigger validation on keydown event
        formik.setFieldTouched(event.target.name, true, false)
        formik.validateField(event.target.name)
    }
    return {
        handleLogin,
        formik,
        isLoading,
        handleKeyDown,
    }
}