import { useFormik } from 'formik'
import { getSelectedBand } from '../../utils/cookieHelper'
import * as Yup from 'yup'
import { useSendChat } from './useChats'

export const useChatForm = () => {
  const { band } = getSelectedBand()
  const { mutate, isLoading } = useSendChat()

  const formik = useFormik({
    initialValues: { message: '' },
    validationSchema: Yup.object().shape({
      message: Yup.string().required('Required')
    }),
    onSubmit: (values) => {
      values.bandId = band.id
      handleSubmit(values)
    },
  })

  const handleSubmit = async (values) => {
    try {
      await mutate(values)
      formik.resetForm()
    } catch (error) {
      console.log('Chat error', error)
    }
  }

  const handleKeyDown = (event) => {
    formik.setFieldTouched(event.target.name, true, false)
    formik.validateField(event.target.name)
  }

  return { formik, isLoading, handleSubmit, handleKeyDown }
}
