import React, { useState, useEffect } from 'react'
import Calendars from '../components/Calendar/Calendars'
import Availability from '../components/Availability'
import { useUserDetails } from '../hooks/users/useUsers'
import { setUser } from '../utils/cookieHelper.js'
import { useLocation } from 'react-router-dom'

export const CalendarPage = () => {
  const location = useLocation()
  const { tab } = location.state || {};
  const tabs = [
    { id: 1, title: 'Booking', content: <Calendars /> },
    { id: 2, title: 'Availability', content: <Availability /> },
  ]

  const defaultTab = tab === 'Availability' ? tabs[1] : tabs[0]
  const [activeTab, setActiveTab] = useState(defaultTab)

  const handleTabClick = (tab) => {
    setActiveTab(tab)
  }

  const { data: userDetails } = useUserDetails()

  useEffect(() => {
    if (userDetails) {
      setUser(userDetails);
    }
  }, [userDetails]);

  return (
    <div className="tab-container p-5">
      <div className="tab-header flex gap-5 cursor-pointer mb-6">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`tab ${tab.id === activeTab.id
              ? 'active text-pink underline underline-offset-8'
              : ''
              }`}
            onClick={() => handleTabClick(tab)}
          >
            {tab.title}
          </div>
        ))}
      </div>
      <div className="tab-content">{activeTab.content}</div>
    </div>
  )
}
