import React from 'react'
import { AiOutlineArrowLeft, AiOutlineLoading3Quarters } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import Input from '../../components/Input'
import Button from '../../components/Button'
// import { useFormik } from 'formik'
// import * as Yup from 'yup'
// import { useMutation } from '@tanstack/react-query'
// import { createSong } from '../../api/BandSongs/songs'
// import { toast } from 'react-toastify'
// import { getUser } from '../../utils/cookieHelper'
import { useSongForm } from '../../hooks/songs/useSongForm'

const AddSong = () => {
  // const { user } = getUser()

  const navigate = useNavigate()
  const handleGoBack = () => {
    navigate(-1)
  }

  const { formik, isLoading } = useSongForm()

  return (
    <div className="flex flex-col p-3">
      <div
        className="flex items-center gap-2  mb-4 cursor-pointer"
        onClick={handleGoBack}
      >
        <AiOutlineArrowLeft />
        <p className=" text-16 font-semibold">Add New Song</p>
      </div>
      <div className="bg-main-bg-dark p-5  w-full lg:w-1/3 m-auto">
        <form
          onSubmit={(e) => {
            e.preventDefault()
            formik.handleSubmit()
          }}
          className="flex flex-col text-left w-full gap-4 mb-4"
        >
          <Input
            value={formik.values.title}
            placeholder="Song Name"
            name="title"
            label=""
            formik={formik}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.title && formik.errors.title}
            classes={formik.errors.title ? 'border border-new-red' : null}
          />
          <p className="text-white">
            After adding a song, you can add it to your set list.
          </p>
          <div className="relative">
            <Button
              bgColor="bg-main-bg-pink"
              text={isLoading ? 'Adding Song...' : 'Add Song'}
              size="md"
              borderRadius="0px"
              type="submit"
              color="white"
              classes={`w-full`}
              disabled={!formik.isValid || !formik.dirty || isLoading}
            />
            {isLoading ? (
              <AiOutlineLoading3Quarters
                className="spin mr-2 absolute right-0 top-3 text-2xl text-white"
                style={{ animation: 'spin 1s infinite linear' }}
              />
            ) : null}
          </div>
          <Button
            type="button"
            bgColor="transparent"
            text="Cancel"
            size="md"
            borderRadius="0px"
            color="white"
            border="2px solid white"
            onClick={handleGoBack}
          />
        </form>
        {/* {addSong.error && (
          <p className="text-red-500">Error: {addSong.error.message}</p>
        )} */}
      </div>
    </div>
  )
}

export default AddSong
