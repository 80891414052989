import { axiosInstance } from '../axiosInterceptor'

export const createSong = async ({ title, bandId }) => {
  const data = await axiosInstance.post(`/bands/${bandId}/songs`, {
    title: title,
  })
  return data
}

export const fetchSongs = async ({ queryKey }) => {
  const bandId = queryKey[1]
  return await axiosInstance.get(`bands/${bandId}/songs`)
}

export const deleteSong = async (value) => {
  // const songId = queryKey[1]
  return await axiosInstance.delete(`bands/${value.bandId}/songs/${value.id}`)
}
