import React from 'react'
import Navbar from './Navbar'
import { Navigate } from 'react-router-dom'
import { getTokens } from '../utils/cookieHelper'

// eslint-disable-next-line react/prop-types
export const RequireAuth = ({ children }) => {
  const { accessToken } = getTokens()

  if (!accessToken) {
    return <Navigate to="/login" />
  }
  return (
    <>
      <Navbar />
      {children}
    </>
  )
}
