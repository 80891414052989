import { React, useState } from 'react'
import PropTypes from 'prop-types'
import {
  RiFileDownloadLine,
  RiFileCopy2Fill,
  RiCheckLine,
  RiCloseLine,
} from 'react-icons/ri'
import { useDroppable } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import SortableItem from './SortableItem'
import SongSortableItem from './SongSortableItem'
import PdfContent from './PdfContent'
import EasyEdit from 'react-easy-edit'
import { renameSetList } from '../../api/BandSongs/set-lists'
import { toast } from 'react-toastify'
import { PDFDownloadLink } from '@react-pdf/renderer'
import {
  useDeleteSetlist,
  useDeleteSongFromSetlist,
  useDuplicateSetlist,
} from '../../hooks/setLists/useSetLists'
import { AiFillDelete, AiOutlineEdit } from 'react-icons/ai'
import { Tooltip } from 'react-tippy'

function SongDropAble({ id, playlistId, title, songs, bandId, isMobile = true }) {
  const [edit, setEdit] = useState(false);
  const { isOver, setNodeRef } = useDroppable({
    id: id,
    data: {
      playlistId: playlistId,
    },
  })
  const cancel = () => {
    setEdit(false);
  }
  const save = (v) => {
    renameSetList(v, bandId, playlistId)
      .then(() => {
        if (!isMobile) toast.success('Title renamed successfully')

        if (edit) {
          toast.success('Title renamed successfully')
          cancel();
        }
      })
      .catch((e) => {
        toast.error(e)
      })
  }
  const deleteSong = useDeleteSongFromSetlist({ bandId: bandId })
  const duplicatedSetList = useDuplicateSetlist()
  const deleteSetlist = useDeleteSetlist()

  const handleDelete = (playlistId, id) => {
    deleteSong.mutate({ bandId, id, playlistId })
  }
  const handleDuplicate = (playlistId) => {
    duplicatedSetList.mutate({ bandId, playlistId })
  }

  const handleDeleteSetlist = (playlistId) => {
    deleteSetlist.mutate({ bandId, playlistId })
  }

  return (
    <SortableContext
      id={id}
      strategy={verticalListSortingStrategy}
      items={songs}
    >
      <li
        ref={setNodeRef}
        className={`${isMobile ? '' : 'setlist'} flex flex-grow items-start justify-between p-2 pr-0 bg-main-bg-dark ${isMobile ? '' : 'max-w-[290px]'}  text-white  ${isOver ? ' bg-fuchsia-400' : ''
          }`}
      >
        <div className="flex flex-col w-full">
          {
            isMobile ? (
              <div className="flex flex-row justify-between items-center ">
                <div className="text-white p-2">
                  <EasyEdit
                    editMode={edit}
                    type="text"
                    value={title}
                    onSave={save}
                    onCancel={cancel}
                    saveButtonLabel={
                      <div className="bg-white pd-2 rounded-md text-green text-xl border border-gray-500 flex justify-center items-center">
                        <RiCheckLine />
                      </div>
                    }
                    cancelButtonLabel={
                      <div className="bg-white pd-2 rounded-md text-red-500 text-xl border border-gray-500 flex justify-center items-center">
                        <RiCloseLine />
                      </div>
                    }
                    attributes={{ name: title, id: 1 }}
                    onHoverCssClass=" text-white cursor-pointer opacity-50"
                  />
                </div>
                {
                  (isMobile && !edit) &&
                  (<div
                    className="text-20 text-white p-2 cursor-pointer"
                    onClick={() => setEdit(true)}
                  >
                    <AiOutlineEdit />
                  </div>)
                }
              </div>
            ) : (
              <div className="hdr flex flex-row justify-between w-full items-start pr-2 mb-4">
                <div className="flex gap-2 flex-grow items-center ">
                  <Tooltip title="Tap to edit" position="top" animation="scale">
                    <div className="text-white relative w-full">
                      <EasyEdit
                        type="text"
                        value={title}
                        onSave={save}
                        onCancel={cancel}
                        saveButtonLabel={
                          <div className="bg-white pd-2 rounded-md text-green text-xl absolute top-[5px] right-[44px] h-[24px] w-[24px] border border-gray-500 flex justify-center items-center">
                            <RiCheckLine />
                          </div>
                        }
                        cancelButtonLabel={
                          <div className="bg-white pd-2 rounded-md text-red-500 text-xl absolute top-[5px] right-[16px] h-[24px] w-[24px] border border-gray-500 flex justify-center items-center">
                            <RiCloseLine />
                          </div>
                        }
                        attributes={{ name: title, id: 1 }}
                        onHoverCssClass=" text-white cursor-pointer opacity-50"
                      // instructions={`Previously: ${title}`}
                      />
                    </div>
                  </Tooltip>
                </div>
                <div className="flex gap-2 mt-1">
                  <Tooltip title="Download as pdf" position="top" animation="scale">
                    <PDFDownloadLink
                      document={<PdfContent title={title} songs={songs} />}
                      fileName="setlist.pdf"
                    >
                      <RiFileDownloadLine></RiFileDownloadLine>
                    </PDFDownloadLink>
                  </Tooltip>

                  <Tooltip title="Duplicate" position="top" animation="scale">
                    <RiFileCopy2Fill
                      onClick={() => handleDuplicate(playlistId)}
                    ></RiFileCopy2Fill>
                  </Tooltip>
                  <Tooltip title="Delete" position="top" animation="scale">
                    <div onClick={() => handleDeleteSetlist(playlistId)}>
                      <AiFillDelete />
                    </div>
                  </Tooltip>
                </div>
              </div>
            )
          }
          <div className="h-[320px] flex flex-grow flex-col overflow-auto overflow-x-hidden pr-2">
            {songs?.map(({ songId, title, id }) => (
              <div key={songId} className="flex justify-between items-center">
                {isMobile ? (
                  <SongSortableItem playlistId={playlistId} id={songId} title={title} />
                ) : (
                  <SortableItem playlistId={playlistId} id={songId} title={title} />
                )}
                <div className="hover:text-pink" onClick={() => handleDelete(playlistId, id)}>
                  <AiFillDelete />
                </div>
              </div>
            ))}
          </div>
        </div>
      </li>
    </SortableContext>
  )
}

SongDropAble.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  bandId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  playlistId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,

  title: PropTypes.string.isRequired,
  songs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isMobile: PropTypes.bool
}

export default SongDropAble
