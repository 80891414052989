import React from 'react'
import { AiOutlineArrowLeft, AiOutlineLoading3Quarters } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import Input from '../Input'
import Button from '../Button'
import { useBandsForm } from '../../hooks/bands/useBandsForm'

const AddBand = () => {
    const navigate = useNavigate()
    const handleGoBack = () => {
        navigate(-1)
    }

    const { formik, isLoading } = useBandsForm()

    return (
        <div className="flex flex-col p-3">
            <div
                className="flex items-center gap-2  mb-4 cursor-pointer"
                onClick={handleGoBack}
            >
                <AiOutlineArrowLeft />
                <p className=" text-16 font-semibold">Add New Band</p>
            </div>
            <div className="bg-main-bg-dark p-5  w-full lg:w-1/3 m-auto">
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        formik.handleSubmit()
                    }}
                    className="flex flex-col text-left w-full gap-4 mb-4"
                >
                    <Input
                        value={formik.values.name}
                        placeholder="Band Name"
                        name="name"
                        label=""
                        formik={formik}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.title && formik.errors.title}
                        classes={formik.errors.title ? 'border border-new-red' : null}
                    />
                    <p className="text-white">
                        Enter the Band Name you would like to add. You will become a band owner and you can manage members of a new band.
                    </p>

                    <div className="relative">
                        <Button
                            bgColor="bg-main-bg-pink"
                            text={isLoading ? 'Adding Band...' : 'Add Band'}
                            size="md"
                            borderRadius="0px"
                            type="submit"
                            color="white"
                            classes={`w-full`}
                            disabled={!formik.isValid || !formik.dirty || isLoading}
                        />
                        {isLoading ? (
                            <AiOutlineLoading3Quarters
                                className="spin mr-2 absolute right-0 top-3 text-2xl text-white"
                                style={{ animation: 'spin 1s infinite linear' }}
                            />
                        ) : null}
                    </div>
                    <Button
                        type="button"
                        bgColor="transparent"
                        text="Cancel"
                        size="md"
                        borderRadius="0px"
                        color="white"
                        border="2px solid white"
                        onClick={handleGoBack}
                    />
                </form>
            </div>
        </div>
    )
}

export default AddBand
