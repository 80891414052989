import { useFormik } from 'formik'
import { useCreateSetlist } from './useSetLists'
import { getSelectedBand } from '../../utils/cookieHelper'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'

export const useSetlistForm = () => {
  const {band} = getSelectedBand()
  const { mutate, isLoading } = useCreateSetlist()
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: { title: '' },
    validationSchema: Yup.object().shape({
      title: Yup.string()
        .required('Required')
        .min(2, 'Set list name too short'),
    }),
    onSubmit: (values) => {
      values.bandId = band.id
      handleSubmit(values)
      navigate(-1)
    },
  })

  const handleSubmit = async (values) => {
    try {
      await mutate(values)
    } catch (error) {
      console.log('Setlist error', error)
    }
  }

  const handleKeyDown = (event) => {
    formik.setFieldTouched(event.target.name, true, false)
    formik.validateField(event.target.name)
  }

  return { formik, isLoading, handleSubmit, handleKeyDown }
}
