import React, { useEffect } from 'react'
import { getUser } from '../../utils/cookieHelper'
import { useNavigate } from 'react-router-dom'
import { useForgotPassHandler } from '../../hooks/auth/components/useForgotPassHandler'
import Input from '../../components/Input'
import Button from '../../components/Button'

const ForgotPasswordPage = () => {
  const { user } = getUser()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search)
  const urlUserId = queryParams.get('userId')
  const urlToken = queryParams.get('token')
  const { formik } = useForgotPassHandler(+urlUserId, urlToken)

  useEffect(() => {
    if (user) {
      navigate('/calendar')
    }
    if (!urlUserId || !urlToken) {
      navigate('/login')
    }
  }, [])
  const handleForm = (e) => {
    e.preventDefault()
    formik.handleSubmit()
  }
  return (
    <div className="flex flex-col items-center h-auto  m-4 mt-24">
      <h2 className="text-6xl mb-12 text-pink">BndMgr.com</h2>
      <div className="flex flex-col text-left w-96 px-4 pt-6 pb-4 bg-main-bg-dark text-white">
        <p className="text-3xl mb-8">Reset Password</p>
        <form
          onSubmit={(e) => handleForm(e)}
          className="flex flex-col text-left w-full gap-4 mb-4"
        >
          <div className="relative">
            <Input
              value={formik.values.password}
              placeholder="Enter Password"
              name="password"
              label="Password"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && formik.errors.password}
              classes={formik.errors.password ? 'border border-new-red' : null}
            />
            {formik.errors.password ? (
              <div className="absolute right-0 top-auto text-new-red text-14">
                {formik.errors.password}
              </div>
            ) : null}
          </div>
          <div className="relative">
            <Input
              value={formik.values.cpassword}
              placeholder="Confirm Password"
              name="cpassword"
              label="Confirm Password"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.cpassword && formik.errors.cpassword}
              classes={formik.errors.cpassword ? 'border border-new-red' : null}
            />
            {formik.errors.cpassword ? (
              <div className="absolute right-0 top-auto text-new-red text-14">
                {formik.errors.cpassword}
              </div>
            ) : null}
          </div>
          {formik.errors.password ? <div>{formik.errors.password}</div> : null}

          <Button
            bgColor="bg-main-bg-pink"
            text="Reset Password"
            size="md"
            borderRadius="0px"
            type="submit"
          />
        </form>
      </div>
    </div>
  )
}

export default ForgotPasswordPage
