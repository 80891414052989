import {
  axiosInstance
} from '../axiosInterceptor'
export const createSetList = async ({
  title,
  bandId
}) => {
  const data = await axiosInstance.post(`/bands/${bandId}/set-lists`, {
    title: title,
  })
  return data
}

export const fetchSetList = async ({
  queryKey
}) => {
  const bandId = queryKey[1]
  try {
    const response = await axiosInstance.get(`bands/${bandId}/set-lists`)
    const updatedData = await updateData(response.data)
    return updatedData
  } catch (error) {
    console.error('Error fetching set list:', error)
    throw error
  }
}

function updateData(jsonData) {
  jsonData.forEach((item) => {
    item.setListId = `${item.title.toLowerCase().replace(/\s/g, '_')}_${
      item.id
    }`

    item.songs.forEach((song, index) => {
      song.order = index + 1
      song.songId = `${song.title.toLowerCase().replace(/\s/g, '_')}_${
        item.id
      }_${song.id}`
    })
  })

  return jsonData
}

function updateItem(item) {
  item.setListId = `${item.title.toLowerCase().replace(/\s/g, '_')}_${item.id}`;

  item.songs.forEach((song, index) => {
    song.order = index + 1;
    song.songId = `${song.title.toLowerCase().replace(/\s/g, '_')}_${item.id}_${song.id}`;
  });

  return item;
}

export const addSongToSetList = async ({
  bandId,
  songId,
  setListId
}) => {
  return await axiosInstance.post(
    `bands/${bandId}/set-lists/${setListId}/songs`, {
      songId: songId
    },
  )
}
export const renameSetList = async (
  title,
  bandId,
  setListId
) => {
  const data = await axiosInstance.put(`bands/${bandId}/set-lists/${setListId}/rename`, {
    title
  })
  return data
}
export const deleteSongFromSetList = async (value) => {
  return await axiosInstance.delete(
    `bands/${value.bandId}/set-lists/${value.playlistId}/songs/${value.id}`,
  )
}
export const deleteSetList = async (value) => {
  return await axiosInstance.delete(
    `bands/${value.bandId}/set-lists/${value.playlistId}`,
  )
}
export const duplicateSetList = async (
  value
) => {
  const data = await axiosInstance.post(`bands/${value.bandId}/set-lists/${value.playlistId}/duplicate`)
  return data
}
export const fetchSetListById = async ({
  queryKey
}) => {
  const bandId = queryKey[1]
  const setListId = queryKey[2]
  try {
    const response = await axiosInstance.get(`bands/${bandId}/set-lists/${setListId}`)
    const updatedItem = await updateItem(response.data)
    return updatedItem
  } catch (error) {
    console.error('Error fetching set list:', error)
    throw error
  }
}
export const orderSong = async ({
  bandId,
  setListId,
  newOrderList
}) => {
  try {
    const response = await axiosInstance.put(`bands/${bandId}/set-lists/${setListId}/songs/orders`, {
      newOrderList
    })
    return response.data
  } catch (error) {
    console.error('Error ordering set list:', error)
    throw error
  }
}