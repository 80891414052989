import React, { useEffect } from 'react'
import { FaEnvelope } from 'react-icons/fa'
import { getUser, setSelectedBandTokens, setUser } from '../../utils/cookieHelper'
import { useNavigate } from 'react-router-dom'
import { useUserDetails } from '../../hooks/users/useUsers'
import { useVerifyEmail } from '../../hooks/auth/useAuth'

const VerifyEmail = () => {
  const { user } = getUser()
  const navigate = useNavigate()
  const { data: userDetails } = useUserDetails()
  const verifyEmail = useVerifyEmail()
  const queryParams = new URLSearchParams(location.search)
  const urlUserId = queryParams.get('userId')
  const urlToken = queryParams.get('token')
  console.log({ urlUserId, urlToken })
  console.log(user)

  useEffect(() => {
    if (!urlUserId || !urlToken) {
      navigate('/calendar')
    }
    if (+urlUserId !== user.id) {
      navigate('/calendar')
    }

    if (userDetails && typeof userDetails !== 'undefined') {
      setUser(userDetails)
      setSelectedBandTokens(userDetails?.data?.bands[0])
    }

    if (user && user.isVerified) {
      navigate('/calendar')
    }
    if (userDetails && userDetails.isVerified) {
      navigate('/calendar')
    }
  }, [userDetails])
  function handleVerification() {
    verifyEmail.mutate({ userId: +urlUserId, token: urlToken })
  }
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-center p-8 bg-gray-100 shadow-md rounded-lg">
        <FaEnvelope
          size={48}
          className="inline-block text-white bg-blue-500 rounded-full p-3"
        />
        <h2 className="text-2xl font-semibold mb-4">Email Verification</h2>
        <p className="text-gray-700 mb-6">
          Please click the button below to verify your email{' '}
        </p>
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full"
          onClick={handleVerification}
        >
          Verify Email
        </button>
      </div>
    </div>
  )
}

export default VerifyEmail
