import {
  axiosInstance
} from '../axiosInterceptor'

export const login = async (email, password) => {
  const response = await axiosInstance.post('/auth/login', {
    email,
    password,
  })
  return response
}

export const register = async (email, password, nickname, bandName) => {
  const response = await axiosInstance.post('/auth/register', {
    email,
    password,
    nickname,
    bandName,
  })
  return response
}
export const registerBybandId = async (email, password, nickname, bandId) => {
  const response = await axiosInstance.post('/auth/register', {
    email,
    password,
    nickname,
    bandId,
  })
  return response
}

export const forgotPassword = async (email) => {
  const response = await axiosInstance.post('/auth/password/forgot', {
    email,
  })
  return response
}
export const verifyEmail = async (userId, token) => {
  const response = await axiosInstance.post('/auth/verify/email', {
    userId,
    token
  })
  return response
}
export const forgotPasswordByToken = async (userId, token, password) => {
  const response = await axiosInstance.put('/auth/password/forgot', {
    userId,
    token,
    password
  })
  return response
}