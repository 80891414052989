import { axiosInstance } from '../axiosInterceptor'

export const updateUserNickname = async (value) => {
  const data = await axiosInstance.post(`users/username`, value)
  return data
}
export const fetchUserDetails = async () => {
  const data = await axiosInstance.get(`users/profile`)
  return data
}
