import {
    useMutation,
    useQuery,
    useQueryClient
} from '@tanstack/react-query'

import {
    toast
} from 'react-toastify'
import {
    fetchBandMembers,
    sendInvite
} from '../../api/Member/member'
import {
    deleteBandMember
} from '../../api/Bands/bands'

export const useSendInvite = (onSuccess) => {
    return useMutation(
        ['inviteMember'],
        (value) => sendInvite(value), {
            onSuccess: (data, variables, context) => {
                toast.success('Invitation Sent Successfully')
                onSuccess && onSuccess(data, variables, context)
            },
            onError: (err, _variables, _context) => {
                console.log(
                    '🚀 ~ file: useMember:22',
                    err,
                    _variables,
                    _context,
                )
                toast.error(err.response.data.message || "Error Inviting")
            },
        }, {
            refetchOnWindowFocus: 'always',
            refetchInterval: false,
            refetchOnMount: true,
            cacheTime: 0,
            staleTime: 60000,
        },
    )
}

export const useFetchBandMembers = (id) => {
    if (id)
        return useQuery(['bandMembers', id], () => fetchBandMembers(id), {
            refetchInterval: false,
            refetchOnMount: 'always',
            refetchOnWindowFocus: true,
            staleTime: 60000,
        })
}

export const useDeleteBandMember = () => {
    const queryClient = useQueryClient()
    return useMutation(
        ['deleteBandMember'],
        (values) => deleteBandMember(values), {
            onSuccess: (data) => {
                toast.success('Member removed Successfully')
                queryClient.invalidateQueries(['bandMembers', data.bandId])
            },
            onError: (err) => {
                toast.error(err.response.data.message || "Error Removing Band Member")
            },
        }, {
            refetchOnWindowFocus: 'always',
            refetchInterval: false,
            refetchOnMount: true,
            cacheTime: 0,
            staleTime: 60000,
        },
    )
}