import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { createSong, deleteSong, fetchSongs } from '../../api/BandSongs/songs'
import { toast } from 'react-toastify'

export const useSongsList = (bandId) => {
  return useQuery(['songs-list', bandId], fetchSongs, {
    // onSuccess,
    // onError,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchOnMount: true,
    cacheTime: 0,
    staleTime: 60000,
  })
}

export const useCreateSong = (onSuccess) => {
  const queryClient = useQueryClient()
  return useMutation(
    ['createSong'],
    (value) => createSong(value),
    {
      onSuccess: (data, variables, context) => {
        toast.success('Added Song Successfully')
        queryClient.invalidateQueries(['songs-list'])
        onSuccess && onSuccess(data, variables, context)
      },
      onError: (err, _variables, _context) => {
        console.log(
          '🚀 ~ file: useSongs.js:17 ~ useCreateSong ~ err, _variables, _context:',
          err,
          _variables,
          _context,
        )
        toast.error('Error adding song')
      },
    },
    {
      refetchOnWindowFocus: 'always',
      refetchInterval: false,
      refetchOnMount: true,
      cacheTime: 0,
      staleTime: 60000,
    },
  )
}

export const useDeleteSong = (songId, onSuccess) => {
  const queryClient = useQueryClient()

  return useMutation(
    ['deleteSong', songId],
    (values) => {
      return deleteSong(values)
    },
    {
      onSuccess: (data, variables, context) => {
        toast.success('Song Deleted Successfully')
        queryClient.invalidateQueries(['songs-list'])
        onSuccess && onSuccess(data, variables, context)
      },
      onError: (err, _variables, _context) => {
        console.log(
          '🚀 ~ file: useSetLists.js:29 ~ useDeleteSongFromSetlist ~ err:',
          err,
          _variables,
          _context,
        )
        toast.error('Failed to delete Song')
      },
    },
  )
}
