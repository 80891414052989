// styles
import './App.css'
// utils
import React from 'react'
import { AuthProvider } from './utils/auth'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import Router from './route'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-tippy/dist/tippy.css'
import queryClient from './utils/queryClientConfig'

function App() {
  return (
    <div className="App bg-main-bg-green min-h-screen flex  justify-center">
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        theme="colored"
        draggable
        pauseOnHover
      />
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <div className="flex flex-col mx-auto h-full lg:w-4/5 md:w-96 w-full">
            <Router />
          </div>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </QueryClientProvider>
      </AuthProvider>
    </div>
  )
}

export default App
