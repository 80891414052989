import {
    useFormik
} from 'formik'
import {
    getSelectedBand
} from '../../utils/cookieHelper'
import * as Yup from 'yup'

import {
    useSendInvite
} from './useMember'

export const useMemberForm = () => {
    const {
        band
    } = getSelectedBand()
    const {
        mutate,
        isLoading
    } = useSendInvite()

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email().required('Required').min(2, 'Invalid Email'),
        }),
        onSubmit: (values) => {
            values.bandId = band.id
            handleSubmit(values)
            formik.setFieldValue("email", "")
        },
    })

    const handleSubmit = async (values) => {
        try {
            mutate(values)
        } catch (error) {
            console.log('Member add error', error)
        }
    }

    const handleKeyDown = (event) => {
        formik.setFieldTouched(event.target.name, true, false)
        formik.validateField(event.target.name)
    }

    return {
        formik,
        isLoading,
        handleSubmit,
        handleKeyDown
    }
}